import { eduApi as api } from './index'
export const addTagTypes = ['order_run'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      orderRunRenderResultToFile: build.mutation<
        OrderRunRenderResultToFileApiResponse,
        OrderRunRenderResultToFileApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/order_run/${queryArg.orderRunId}/render-result`,
          method: 'POST',
          params: { result_type: queryArg.resultType },
        }),
        invalidatesTags: ['order_run'],
      }),
      orderRunExpandResult: build.mutation<
        OrderRunExpandResultApiResponse,
        OrderRunExpandResultApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/order_run/${queryArg.orderRunId}/expand-result`,
          method: 'POST',
        }),
        invalidatesTags: ['order_run'],
      }),
      orderRunList: build.query<OrderRunListApiResponse, OrderRunListApiArg>({
        query: (queryArg) => ({
          url: `/edu/order_run/`,
          params: {
            order_id: queryArg.orderId,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ['order_run'],
      }),
      orderRunLike: build.mutation<OrderRunLikeApiResponse, OrderRunLikeApiArg>(
        {
          query: (queryArg) => ({
            url: `/edu/order_run/${queryArg.orderRunId}`,
            method: 'PUT',
          }),
          invalidatesTags: ['order_run'],
        }
      ),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as order_runApi }
export type OrderRunRenderResultToFileApiResponse =
  /** status 200 Successful Response */ any
export type OrderRunRenderResultToFileApiArg = {
  orderRunId: string
  resultType: 'completion_result' | 'expanded_result'
}
export type OrderRunExpandResultApiResponse =
  /** status 200 Successful Response */ OrderRunResponse
export type OrderRunExpandResultApiArg = {
  orderRunId: string
}
export type OrderRunListApiResponse =
  /** status 200 Successful Response */ OrderRunResponse[]
export type OrderRunListApiArg = {
  orderId: string
  limit?: number
  offset?: number
}
export type OrderRunLikeApiResponse =
  /** status 200 Successful Response */ OrderRunResponse
export type OrderRunLikeApiArg = {
  orderRunId: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type OrderStatus =
  | 'Created'
  | 'In Progress'
  | 'Completed'
  | 'Failed'
  | 'Deleted'
export type CompletionResultResponse = {
  /** An extended version of the result for enhanced detail or context. */
  response?: string | null
}
export type ExpandedResultResponse = {
  /** An extended version of the result for enhanced detail or context. */
  response?: string | null
}
export type OrderRunResponse = {
  _id: string
  order_id: string
  status: OrderStatus
  completion_result?: CompletionResultResponse | null
  expanded_result?: ExpandedResultResponse | null
  created_at: string
  completed_at?: string | null
  liked?: boolean | null
  user_feedback?: string | null
  temperature?: number | null
}
export const {
  useOrderRunRenderResultToFileMutation,
  useOrderRunExpandResultMutation,
  useOrderRunListQuery,
  useLazyOrderRunListQuery,
  useOrderRunLikeMutation,
} = injectedRtkApi
