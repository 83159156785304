import type { FormItemProps } from 'antd'
import type { SelectProps } from 'antd/lib'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import type { SexEnum } from '../../redux/api/auth/auth'
import type { FormField } from '../../utils/modelDescriptor/formFields'

import type { FormElementProps } from './helpers'
import FormSelect from './Select'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  selectProps?: SelectProps
} & FormElementProps

export const mapSex = (key: SexEnum, t: TFunction) => {
  const sexMapping: {
    [k in SexEnum]: string
  } = {
    male: t('auth.user.fields.sex.options.male'),
    female: t('auth.user.fields.sex.options.female'),
  }
  return sexMapping[key]
}

export default function SexSelect({
  formField,
  itemProps,
  selectProps,
  ...rest
}: Props) {
  const { t } = useTranslation()

  return (
    <FormSelect
      formField={formField}
      itemProps={{
        required: false,
        ...itemProps,
      }}
      options={Object.entries({
        male: mapSex('male', t),
        female: mapSex('female', t),
      }).map(([k, v]) => ({
        key: k,
        value: k,
        label: v,
      }))}
      {...selectProps}
      {...rest}
    />
  )
}
