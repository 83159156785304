import { Button, Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '../../../../app/hooks'
import type { PlanResponse } from '../../../../redux/api/edu/plan'
import { setIsPromoOpen } from '../../../../redux/slices/mainSlice'
import { paths } from '../../../../routes/paths'

const { Title, Paragraph } = Typography

function SubscriptionPlans({
  proPlan,
  basicPlan,
}: {
  basicPlan: PlanResponse
  proPlan: PlanResponse
}) {
  const { t } = useTranslation()

  type TPlanDefinition = {
    type: PlanResponse['name']
    name: string
    // price: string
    cta: string
    onClick: () => void
    // features: DescriptionsProps['items']
  }

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const plans: TPlanDefinition[] = [proPlan, basicPlan].map(
    ({
      name,
      is_active,
      is_trial_available,
      billing_cycle_days,
      description,
      price,
      trial_days,
    }) => ({
      type: name,
      name: t(`edu.subscription.plans.${name}.title`),
      // price: kzt_month
      //   ? t('edu.subscription.rate.monthly.kzt', {
      //       price: kzt_month,
      //     })
      //   : t('edu.subscription.rate.free'),
      onClick: () => {
        if (name === 'basic') {
          navigate(paths.eduPlayground)
        } else {
          dispatch(setIsPromoOpen(true))
        }
      },
      // features: [
      //   {
      //     label: t('edu.subscription.features.submissionsPerDay.title'),
      //     span: 'filled',
      //     children: t('edu.subscription.features.submissionsPerDay.value', {
      //       submissionsPerDay: submissions_per_day,
      //     }),
      //   },
      //   {
      //     label: t('edu.subscription.features.maxDisciplines.title'),
      //     span: 'filled',
      //     children: t('edu.subscription.features.maxDisciplines.value', {
      //       maxDisciplines: max_disciplines,
      //     }),
      //   },
      //   {
      //     label: t('edu.subscription.features.docx.title'),
      //     span: 'filled',
      //     children: docx
      //       ? t('edu.subscription.features.docx.valueYes')
      //       : t('edu.subscription.features.docx.valueNo'),
      //   },
      // ],
      cta: t(`edu.subscription.plans.${name}.buy`),
    }),
  )

  return (
    <section
      id="pricing"
      style={{
        padding: '5rem 0', // Approximate Tailwind py-20
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem', // Approx. px-6
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <Title
            level={2}
            style={{
              color: 'rgb(85 37 130 / var(--tw-bg-opacity, 1))',
              fontWeight: 'bold',
              fontSize: '1.875rem', // Approx. text-3xl
              marginBottom: 0,
            }}
          >
            {t('pages.landing.subscription.title')}
          </Title>
          <Paragraph style={{ color: '#4B5563', marginTop: '1rem' }}>
            {t('pages.landing.subscription.description')}
          </Paragraph>
        </div>

        <Row gutter={[24, 32]} justify="center">
          {plans.map((plan, index) => (
            <Col key={index} xs={24} md={12}>
              <div
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '0.5rem',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    padding: '1.5rem',
                    background: 'var(--violet)',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <Title
                    level={3}
                    style={{
                      fontWeight: '600',
                      fontSize: '1.5rem', // Approx. text-2xl
                      marginBottom: '0.5rem',
                      color: 'white',
                    }}
                  >
                    {plan.name}
                  </Title>
                  {/* <Paragraph
                    style={{
                      fontSize: '1.25rem', // Approx. text-xl
                      margin: 0,
                      color: 'white',
                    }}
                  >
                    {plan.price}
                  </Paragraph> */}
                </div>
                <ul
                  style={{
                    padding: '1.5rem',
                    listStyle: 'none',
                    margin: 0,
                  }}
                >
                  {/* <Descriptions
                    bordered
                    title={t('edu.subscription.features.title')}
                    items={plan.features}
                  /> */}
                </ul>
                <div style={{ padding: '1.5rem', textAlign: 'center' }}>
                  <Button
                    type={plan.type === 'basic' ? 'default' : 'primary'}
                    onClick={plan.onClick}
                  >
                    {plan.cta}
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default SubscriptionPlans
