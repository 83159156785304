// ----------------------------------------------------------------------

import {
  DeleteOutlined,
  EditFilled,
  FileAddOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Card, Col, List, Popconfirm, Row, Tag, Typography } from 'antd'
import Sider from 'antd/es/layout/Sider'
import type { TagProps } from 'antd/lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Fractal from '../../pages/Landing/components/components/Fractal'
import type { OrderStatus } from '../../redux/api/edu/order'
import {
  useOrderListQuery,
  useOrderSoftDeleteMutation,
} from '../../redux/api/edu/order'
import {
  getOrderModelDescriptor,
  mapStatusT,
  orderSetEditId,
} from '../../redux/slices/edu/order'
import { getOnSelect } from '../../utils/onSelect'
import { formatDate } from '../../utils/time'

import OrderForm from './components/OrderForm'
import { formatTaskTypePath } from './components/TaskTypeSelect'

export default function PlayGroundSider() {
  const { teachers_discipline_id, category_id, subcategory_id, order_id } =
    useParams()
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)

  const [ordersFormOpen, setOrdersFormOpen] = useState(false)
  const { user } = useAppSelector((state) => state.auth)

  const { editId } = useAppSelector((state) => state.edu.order)
  const dispatch = useDispatch()

  const fmd = getOrderModelDescriptor(t)
  return (
    <>
      <Sider
        collapsed={collapsed}
        width={400}
        style={{
          borderRadius: 10,
          marginInline: '15px',
        }}
      >
        <div
          style={{
            borderColor: 'var(--color-light-gray)',
            border: '10',
            borderRadius: '12px',
            borderWidth: '1px',
            background: 'var(--color-white)',
            padding: '15px',
          }}
        >
          <Row gutter={2}>
            <Col span={4}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  marginTop: '8px',
                  marginLeft: '8px',
                }}
              />
            </Col>
            {!collapsed && (
              <>
                <Col span={17}>
                  <Typography.Title level={3} style={{ marginTop: 8 }}>
                    {t('layouts.playground.sider.title')}
                  </Typography.Title>
                </Col>
                <Col style={{ marginTop: 8, marginRight: 8 }} span={2}>
                  <Button
                    type="primary"
                    onClick={() => setOrdersFormOpen(true)}
                    disabled={
                      teachers_discipline_id === undefined ||
                      category_id === undefined ||
                      subcategory_id === undefined
                    }
                    icon={<FileAddOutlined />}
                  >
                    {/* {fmd.form.action.create()} */}
                  </Button>
                </Col>
              </>
            )}
          </Row>
          {subcategory_id ? (
            <Wrapper subcategory_id={subcategory_id} collapsed={collapsed} />
          ) : (
            <Fractal
              style={{
                borderRadius: 15,
                // filter: 'blur(4px)',
              }}
            >
              <div
                style={{
                  height: '100vh',
                }}
              />
            </Fractal>
          )}

          <OrderForm
            open={ordersFormOpen}
            setOpen={setOrdersFormOpen}
            orderEditId={editId}
            onClose={() => {
              setOrdersFormOpen(false)
              dispatch(orderSetEditId(undefined))
            }}
          />
        </div>
      </Sider>
    </>
  )
}

const Wrapper = ({
  collapsed,
  subcategory_id,
}: {
  subcategory_id: string
  collapsed: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { teachers_discipline_id, category_id, order_id } = useParams()

  const { data: orders, isLoading: ordersIsLoading } = useOrderListQuery({
    subcategoryId: subcategory_id,
  })
  const [orderDelete, { isLoading: isDeleteLoading }] =
    useOrderSoftDeleteMutation()

  const onSelect = getOnSelect(
    navigate,
    teachers_discipline_id,
    category_id,
    subcategory_id,
  )
  const mapStatusColors: { [k in OrderStatus]: TagProps['color'] } = {
    'In Progress': 'blue',
    Completed: 'green',
    Deleted: 'default',
    Failed: 'error',
    Created: 'purple',
  }
  return (
    <List
      dataSource={orders}
      loading={ordersIsLoading}
      renderItem={(order) => {
        const isEditDisabled = !(
          order.status === 'Created' || order.status === 'Failed'
        )
        const isLoading = isDeleteLoading
        return (
          <List.Item
            style={{
              padding: 0,
              paddingBlock: 5,
            }}
          >
            <Card
              title={
                <div
                  style={{
                    color: 'var(--color-primary-purple)',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '100%',
                  }}
                >
                  <Typography.Title
                    level={4}
                    style={{
                      color: 'var(--color-primary-purple)',
                      padding: 0,
                      margin: 0,
                      wordWrap: 'break-word',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                      maxWidth: '100%',
                    }}
                  >
                    {!collapsed ? order.title : order.title[0]}
                  </Typography.Title>
                  {!collapsed && (
                    <div>
                      <Button
                        type="default"
                        loading={isLoading}
                        disabled={isEditDisabled}
                        style={{ background: 'white' }}
                        onClick={() => {
                          dispatch(orderSetEditId(order._id))
                        }}
                        icon={<EditFilled />}
                      />
                      &nbsp;
                      <Popconfirm
                        title={t(
                          'edu.order.form.actions.delete.popConfirm.title',
                        )}
                        description={t(
                          'edu.order.form.actions.delete.popConfirm.description',
                        )}
                        icon={
                          <QuestionCircleOutlined style={{ color: 'red' }} />
                        }
                        onConfirm={() => {
                          orderDelete({ orderId: order._id })
                        }}
                      >
                        <Button
                          type="primary"
                          loading={isLoading}
                          danger
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </div>
                  )}
                </div>
              }
              hoverable
              style={{
                width: '100%',
                borderRadius: 12,
                outlineColor: 'var(--color-primary-orange)',
                outlineWidth: '2.4px',
                outlineStyle: 'auto',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                background:
                  order_id === order._id
                    ? 'linear-gradient(to right, orange, rgb(92 6 173))'
                    : undefined,
              }}
              styles={{
                body: {
                  transform: 'translateY(0)',
                  padding: 8,
                  margin: 8,
                },
                header: {
                  padding: 8,
                  margin: 8,
                },
              }}
              onClick={() => {
                onSelect('order')(order._id)
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-5px)'
                e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)'
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translateY(0)'
                e.currentTarget.style.boxShadow = 'none'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  justifyContent: 'space-between',
                  padding: 0,
                  margin: 0,
                }}
              >
                {!collapsed ? (
                  formatDate(order.created_at)
                ) : (
                  <FileTextOutlined />
                )}
                <div style={{ display: 'flex' }}>
                  {!collapsed && (
                    <div>
                      <Tag color="blue">
                        {t('edu.order.fields.task_type.tag', {
                          task_type: t(formatTaskTypePath(order.task_type)),
                        })}
                      </Tag>
                    </div>
                  )}
                  {!collapsed && (
                    <div>
                      <Tag color={mapStatusColors[order.status]}>
                        {t('edu.order.fields.status.tag', {
                          status: mapStatusT(order.status, t),
                        })}
                      </Tag>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </List.Item>
        )
      }}
      split={false}
    />
  )
}
