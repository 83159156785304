import { Form, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
// ----------------------------------------------------------------------

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import type { IFormBody } from '../../../components/Form/helpers'
// import ProntoTitle from '../../components/ProntoTitle'
import Debug from '../../../components/helpers/Debug'
import type {
  BodyLoginAuthAuthLoginPost,
  SignUpUserBody,
} from '../../../redux/api/auth/auth'
import {
  useLoginMutation,
  useSignUpMutation,
} from '../../../redux/api/auth/auth'
import {
  getUserModelDescriptor,
  setUser,
} from '../../../redux/slices/auth/userSlice'
import { DefaultPath } from '../../../routes/paths'
import { buildFormDataBody } from '../../../utils/form'

import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
// ----------------------------------------------------------------------

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
}

export default function AuthModal({ open, setOpen }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const signUpMD = getUserModelDescriptor(t, 'signUpForm')
  const loginMD = getUserModelDescriptor(t, 'loginForm')
  const forgotMD = getUserModelDescriptor(t, 'forgotForm')
  const { accessToken, user } = useAppSelector((state) => state.auth)
  const [login, { error, isLoading }] = useLoginMutation()
  const dispatch = useAppDispatch()

  type FormBody = IFormBody<Required<BodyLoginAuthAuthLoginPost>, never>
  const [loginForm] = Form.useForm<FormBody>()
  const [signUpForm] =
    Form.useForm<IFormBody<Required<SignUpUserBody>, never>>()

  useEffect(() => {
    if (error !== undefined) {
      // console.log(error)
      loginMD.handleErrors(loginForm as any, error)
    }
  }, [error])

  // if (accessToken) {
  //   return <Navigate to={DefaultPath} />
  // }

  const onFinish = async (values: Required<BodyLoginAuthAuthLoginPost>) => {
    const bodyLoginAuthAuthLoginPost =
      buildFormDataBody<BodyLoginAuthAuthLoginPost>({
        username: values.username,
        password: values.password,
      })

    try {
      const { access_token, refresh_token, user } = await login({
        bodyLoginAuthAuthLoginPost,
      }).unwrap()
      dispatch(
        setUser({
          accessToken: access_token,
          refreshToken: refresh_token,
          user,
        }),
      )

      navigate(
        location.state?.from.pathname !== DefaultPath
          ? location.state?.from.pathname
          : DefaultPath,
      )
      setOpen(false)
    } catch (error) {
      // toast.error('Не правильно введен логин или пароль')
      console.error((error as any).data.detail)
    }
  }

  const handleClose = () => setOpen(false)

  const [formType, setFormType] = useState<'login' | 'sign-up' | 'forgot'>(
    'login',
  )
  const title =
    formType === 'login'
      ? t('auth.user.loginForm.title')
      : formType === 'sign-up'
        ? t('auth.user.signUpForm.title')
        : t('auth.user.forgotForm.title')

  const okText =
    formType === 'login'
      ? t(`${loginMD.form.action.describe('submit')}`)
      : formType === 'sign-up'
        ? t(`${signUpMD.form.action.describe('submit')}`)
        : t(`${forgotMD.form.action.describe('submit')}`)

  const [isTOCAccepted, setIsTOCAccepted] = useState(false)

  const [signUp, { isLoading: isSignUpLoading, error: signUpError }] =
    useSignUpMutation()

  useEffect(() => {
    if (signUpError !== undefined) {
      console.log(signUpError)
      signUpMD.handleErrors(signUpError as any, error)
    }
  }, [error])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      title={title}
      okText={okText}
      okType="primary"
      cancelButtonProps={{
        disabled: isSignUpLoading,
      }}
      okButtonProps={{
        htmlType: 'submit',
        disabled: (!isTOCAccepted && formType === 'sign-up') || isSignUpLoading,
      }}
      onOk={() => {
        if (formType === 'login') {
          loginForm.submit()
        } else if (formType === 'sign-up') {
          signUpForm.submit()
        } else {
        }
      }}
    >
      <Debug
        objects={{
          v: !isTOCAccepted && formType === 'sign-up',
          isTOCAccepted,
          formType,
        }}
      />
      {formType === 'login' ? (
        <LoginForm
          onSuccess={() => setOpen(false)}
          form={loginForm}
          onSignUp={() => setFormType('sign-up')}
        />
      ) : formType === 'sign-up' ? (
        <SignUpForm
          onSuccess={() => {
            setOpen(false)
            setFormType('login')
          }}
          isLoading={isSignUpLoading}
          isTOCAccepted={isTOCAccepted}
          signUp={async ({ signUpUserBody }) => {
            await signUp({ signUpUserBody })
          }}
          setIsTOCAccepted={setIsTOCAccepted}
          form={signUpForm}
          onLogin={() => setFormType('login')}
        />
      ) : (
        <></>
      )}
    </Modal>
  )
}
