import type { PlanFeatureResponse } from '../../../redux/api/auth/auth'

export const SubscriptionFeatures = ({
  features,
}: {
  features: PlanFeatureResponse[]
}) => {
  return (
    <ul
      style={{
        listStyleType: 'disc',
        textAlign: 'left',
        maxHeight: '300px',
        minHeight: '150px',
      }}
    >
      {features.map((feature, index) => (
        <li key={index} style={{ marginBottom: '8px' }}>
          {feature.description}
        </li>
      ))}
    </ul>
  )
}
