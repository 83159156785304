import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { FormInstance } from 'antd'

import type { FormFields } from './modelDescriptor/formFields'

interface errResponse<T, errN extends number> {
  status: errN
  data: {
    message: Partial<Record<keyof T, string[]>>
  }
}

export function is400Error<T>(
  obj: any,
  keys: string[],
): obj is errResponse<T, 400> {
  return (
    obj &&
    typeof obj === 'object' &&
    obj.status === 400 &&
    obj.data &&
    typeof obj.data === 'object' &&
    Object.keys(obj.data.message).every((key) => keys.includes(key))
  )
}

export const emptyStringsToNull = <T extends Object>(body: T) => {
  const newBody: T = { ...body } // Create a shallow copy of the body

  for (const key in newBody) {
    if (Object.prototype.hasOwnProperty.call(newBody, key)) {
      const value = newBody[key]
      if (value === '') {
        newBody[key] = null as any // Set empty string values to null
      }
    }
  }

  return newBody
}

export const buildFormDataBody = <T extends Object>(initialBody: T) => {
  const cleanedBody = emptyStringsToNull<T>(initialBody)
  const bodyFormData = new FormData()
  for (const [key, value] of Object.entries(cleanedBody)) {
    if (value === null) {
      bodyFormData.append(key, '')
    } else if (value !== undefined) bodyFormData.append(key, value as any)
  }
  return bodyFormData as any as T
}

export const handleErrors = <
  ModelFormBody extends Object,
  Body extends Object,
  Keys extends string,
>(
  form: FormInstance<ModelFormBody>,
  queryErrors: FetchBaseQueryError | SerializedError | undefined,
  formFields: FormFields<Keys>,
) => {
  const ibr = is400Error<Body>(queryErrors, Object.keys(formFields))
  console.log(ibr)
  if (ibr) {
    for (const [name, errorKeys] of Object.entries(queryErrors.data.message)) {
      errorKeys.map((errorKey: string) =>
        console.log(formFields[name as Keys].get_error(errorKey)),
      )
      form.setFields([
        {
          // ? wtf is this
          name: name as any,
          errors: errorKeys.map((errorKey: string) =>
            formFields[name as Keys].get_error(errorKey),
          ),
        },
      ])
    }
  }
}
