import { Button, Card, Form, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import type { IFormBody } from '../../../components/Form/helpers'
import PhoneNumberInput from '../../../components/Form/PhoneNumberInput'
import Debug from '../../../components/helpers/Debug'
import type { SubscriptionRequestBody } from '../../../redux/api/edu/subscription'
import { useSubscriptionRequestMutation } from '../../../redux/api/edu/subscription'
import { getSubscriptionModelDescriptor } from '../../../redux/slices/edu/subscription'

const { Title } = Typography
type Props = {
  plan_id: string
}

export default function KaspiTransferForm({ plan_id }: Props) {
  const initialValues: Partial<SubscriptionRequestBody> = {
    plan_id,
    phone_number: '+7 ',
  }

  type FormBody = IFormBody<Partial<SubscriptionRequestBody>, never>
  const { t } = useTranslation()
  const subscriptionMD = getSubscriptionModelDescriptor(t)
  // const [paymentCreate, { isLoading }] = usePaymentCreateMutation()
  const [subscriptionRequestCreate, { isLoading }] =
    useSubscriptionRequestMutation()
  const [form] = Form.useForm<FormBody>()
  const values = Form.useWatch([], form)

  return (
    <Card>
      <Debug objects={{ values }} />
      <Form
        form={form}
        initialValues={initialValues}
        validateTrigger="onBlur"
        onFinish={({ phone_number, plan_id }) => {
          if (phone_number && plan_id)
            subscriptionRequestCreate({
              subscriptionRequestBody: {
                phone_number,
                plan_id,
              },
            })
        }}
      >
        <PhoneNumberInput
          formField={subscriptionMD.fields.phone_number}
          itemProps={{
            rules: [
              { required: true, message: 'Please enter your phone number!' },
            ],
          }}
        />
        <Form.Item name={subscriptionMD.fields.plan_id.name} hidden />
        <Typography>{t('edu.subscription.form.description')}</Typography>
        <Row style={{ justifyContent: 'center', marginTop: 50 }}>
          <Button
            type="primary"
            htmlType="submit"
            // size="small"
            style={{
              background: 'var(--color-primary-purple)',
              width: '100%',
              height: 'auto',
              // fontSize: 18,
            }}
            disabled={isLoading}
          >
            <Title
              level={4}
              style={{
                color: 'white',
                margin: 0,
              }}
            >
              {t(
                `${subscriptionMD.form.action.domain}.actions.subscriptionRequest`,
              )}
            </Title>
          </Button>
        </Row>
      </Form>
    </Card>
  )
}
