import { Card, Col, Row, Skeleton, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import { ProfileRoute } from '../../routes'

import ProfileCard from './components/ProfileCard'

interface Props {}
const { Title } = Typography

export default function ProfilePage({}: Props) {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth)

  return (
    <Page title={t(ProfileRoute.title)} style={{ minHeight: '100vh' }}>
      <Debug debug objects={{ user }} />
      <Row gutter={16}>
        <Col lg={14} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          {user && <ProfileCard user={user} />}
        </Col>
        <Col lg={10}>
          <Card
            style={{
              width: '100%',
              background: 'var(--color-light-gray)',
            }}
          >
            <Title level={3}>Statistics - coming soon!</Title>
            <Spin spinning delay={500}>
              <Skeleton.Node
                active={false}
                style={{
                  width: '100%',
                  height: 800,
                  borderRadius: 12,
                  outlineColor: 'var(--color-primary-orange)',
                  outlineWidth: '2.4px',
                  outlineStyle: 'auto',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  background:
                    'linear-gradient(to right, orange, rgb(92 6 173))',
                }}
                fullSize
              ></Skeleton.Node>
            </Spin>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}
