import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import ChooseSubscriptionTitle from '../../../components/SubscriptionForm/components/ChooseSubscriptionTitle'
import type { TPlan } from '../../../components/SubscriptionForm/components/PlanCard'
import PlanCard from '../../../components/SubscriptionForm/components/PlanCard'

type Props = {
  planId: string | null
  onPlanSelect: (planId: string) => void
  plans: TPlan[]
}
export default function SubscriptionChoiceStep({
  planId,
  plans,
  onPlanSelect,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <ChooseSubscriptionTitle />
      <Row gutter={16} justify="center">
        {plans.map((plan) => (
          <PlanCard
            selectedPlanId={planId}
            plan={plan}
            selectable
            onSelect={onPlanSelect}
          />
        ))}
      </Row>
    </>
  )
}
