import { Col, Form, Modal, Row } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppDispatch } from '../../../app/hooks'
import type { IFormBody } from '../../../components/Form/helpers'
import FormInput from '../../../components/Form/Input'
import LanguageSelect, {
  mapLanguageCode,
} from '../../../components/Form/LanguageSelect'
import FormTextArea from '../../../components/Form/TextArea'
import Debug from '../../../components/helpers/Debug'
import { getCurrentLanguage } from '../../../i18n'
import type {
  Language,
  OrderBody,
  OrderResponse,
  TaskType,
} from '../../../redux/api/edu/order'
import {
  orderApi,
  useLazyOrderGetQuery,
  useOrderCreateMutation,
  useOrderUpdateMutation,
} from '../../../redux/api/edu/order'
import { getOrderModelDescriptor } from '../../../redux/slices/edu/order'

import LevelSelect from './LevelSelect'
import TaskTypeSelect from './TaskTypeSelect'

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose: () => void
  orderEditId: string | undefined
}

export default function OrderForm({
  open,
  setOpen,
  onClose,
  orderEditId,
}: Props) {
  type FormBody = IFormBody<Partial<OrderBody>, never>
  const [form] = Form.useForm<FormBody>()
  const { t } = useTranslation()
  const fmd = getOrderModelDescriptor(t)
  const [orderCreate, { error: createError, isSuccess: isSuccessCreated }] =
    useOrderCreateMutation()
  const [orderUpdate, { error: updateError, isSuccess: isSuccessUpdated }] =
    useOrderUpdateMutation()
  const [order, setOrder] = useState<OrderResponse | undefined>(undefined)
  const [orderGet] = useLazyOrderGetQuery()

  useEffect(() => {
    if (createError !== undefined) {
      fmd.handleErrors(form as any, createError)
    }
  }, [createError])

  useEffect(() => {
    if (updateError !== undefined) {
      fmd.handleErrors(form as any, updateError)
    }
  }, [updateError])

  useEffect(() => {
    if (isSuccessCreated) {
      fmd.toast.created()
    }
  }, [isSuccessCreated])

  useEffect(() => {
    if (isSuccessUpdated) {
      fmd.toast.updated()
    }
  }, [isSuccessUpdated])

  useEffect(() => {
    if (orderEditId) {
      orderGet({ orderId: orderEditId })
        .unwrap()
        .then((resp) => {
          setOrder(resp)
          setOpen(true)
        })
    }
  }, [orderEditId])

  const { teachers_discipline_id, category_id, subcategory_id, order_id } =
    useParams()
  const dispatch = useAppDispatch()

  const handleOk = async () => {
    const {
      title,
      instruction,
      input_text,
      level,
      task_type,
      response_language,
    } = form.getFieldsValue()
    console.log(
      teachers_discipline_id,
      title,
      instruction,
      category_id,
      subcategory_id,
      task_type,
    )
    if (
      teachers_discipline_id &&
      title &&
      instruction &&
      category_id &&
      subcategory_id &&
      task_type
    ) {
      if (order !== undefined) {
        await orderUpdate({
          orderId: order._id,
          orderUpdateBody: {
            teachers_discipline_id,
            category_id,
            subcategory_id: subcategory_id,
            level,
            task_type,
            title,
            instruction,
            input_text,
            response_language,
          },
        })
          .unwrap()
          .then(() => {
            dispatch(orderApi.util.invalidateTags(['order']))
            onClose()
          })
      } else {
        await orderCreate({
          orderBody: {
            teachers_discipline_id,
            category_id,
            subcategory_id: subcategory_id,
            response_language,
            //
            level,
            task_type,
            title,
            instruction,
            input_text,
          },
        })
          .unwrap()
          .then(() => {
            dispatch(orderApi.util.invalidateTags(['order']))
            onClose()
          })
      }
    }
  }
  const values = Form.useWatch([], form)

  const handleClose = () => {
    console.log(values)
    onClose()
    setOrder(undefined)
    form.resetFields()
    console.log('reset2', values)
  }

  const defaultResponseLanguage: Language | undefined = getCurrentLanguage()
  const getDefaultInstruction = (
    taskType: TaskType,
    responseLanguage: Language,
  ) => {
    return t(`edu.order.fields.instruction.default.${taskType}`, {
      lng: mapLanguageCode(responseLanguage),
    })
  }
  const initialValues: Partial<OrderBody> = {
    category_id: order?.category_id,
    level: order?.level,
    subcategory_id: order?.subcategory_id,
    task_type: order?.task_type,
    teachers_discipline_id: order?.teachers_discipline_id,
    title: order?.title,
    input_text: order?.input_text,
    instruction: order?.instruction,
    response_language: order?.response_language || defaultResponseLanguage,
  }

  const valuesTaskType = values?.task_type
  const valuesResponseLanguage = values?.response_language

  // const prevInstruction = useRef<string>('')
  const prevResponseLanguage = useRef<Language | undefined>(undefined)
  const prevValuesTaskType = useRef<TaskType | undefined>(undefined)

  useEffect(() => {
    if (values && valuesTaskType && valuesResponseLanguage) {
      // 1. Get the new default instruction for the current task type.
      const newDefault = getDefaultInstruction(
        valuesTaskType,
        valuesResponseLanguage,
      )

      const prevInstruction =
        prevResponseLanguage.current && prevValuesTaskType.current
          ? getDefaultInstruction(
              prevValuesTaskType.current,
              prevResponseLanguage.current,
            )
          : undefined

      // 2. Check if the user’s current instruction is either:
      //    - blank, or
      //    - still the old default (meaning the user never changed it).
      if (values.instruction === '' || values.instruction === prevInstruction) {
        // User hasn't changed the instruction, so update it to the new default
        form.setFieldValue('instruction', newDefault)
        prevResponseLanguage.current = valuesResponseLanguage
        prevValuesTaskType.current = valuesTaskType
      }

      // 3. Update the ref so next render we know what the previous default was
      // prevInstruction.current = newDefault
    }
  }, [valuesTaskType, valuesResponseLanguage])

  return (
    <Modal
      title={
        order !== undefined ? fmd.form.title.update() : fmd.form.title.create()
      }
      open={open}
      width={'800px'}
      onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleClose}
      okText={''}
      cancelText={''}
      //   okButtonProps={{ title: createBtnText }}
      //   cancelButtonProps={{ title: cancelBtnText }}
      afterOpenChange={() => {
        console.log(initialValues)
        console.log(order)
        console.log(orderEditId)
        console.log('reset')
        form.resetFields()
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        size="small"
        initialValues={initialValues}
      >
        <Debug debug objects={{ initialValues }} />
        <Debug debug objects={{ values }} />
        <Row gutter={15}>
          <Col xs={18}>
            <FormInput formField={fmd.fields.title} />
          </Col>
          <Col xs={6}>
            <LanguageSelect formField={fmd.fields.response_language} />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col xs={12}>
            <TaskTypeSelect formField={fmd.fields.task_type} />
          </Col>
          <Col xs={12}>
            <LevelSelect formField={fmd.fields.level} />
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <FormTextArea
              formField={fmd.fields.instruction}
              textAreaProps={{
                rows: 15,
              }}
            />
          </Col>
          <Col span={24}>
            <FormTextArea
              formField={fmd.fields.input_text}
              textAreaProps={{
                rows: 3,
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
