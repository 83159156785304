import type { PopconfirmProps } from 'antd'
import { Button, Popconfirm, Switch, Table, Tag, Typography } from 'antd'
import type { TableProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'

import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import type { UserAdminResponse } from '../../redux/api/auth/user'
import {
  useUserActivateMutation,
  useUserDeleteMutation,
  useUserListQuery,
} from '../../redux/api/auth/user'
import { getUserModelDescriptor } from '../../redux/slices/auth/userSlice'
import { AdminTenantsRoute } from '../../routes'

type Props = {}

export default function AdminUsers({}: Props) {
  const { data } = useUserListQuery()
  const { t } = useTranslation()
  const umd = getUserModelDescriptor(t)
  const [activate] = useUserActivateMutation()
  const [deleteUser] = useUserDeleteMutation()
  const confirm: PopconfirmProps['onConfirm'] = (e) => {
    console.log(e)
  }

  const cancel: PopconfirmProps['onCancel'] = (e) => {
    console.log(e)
  }
  const columns: TableProps<UserAdminResponse>['columns'] = [
    {
      title: umd.fields.email.label,
      dataIndex: umd.fields.email.name,
      key: umd.fields.email.name,
      render: (text) => <>{text}</>,
    },
    {
      title: umd.fields.username.label,
      dataIndex: umd.fields.username.name,
      key: umd.fields.username.name,
      render: (text) => <>{text}</>,
    },
    {
      title: umd.fields.is_admin.label,
      dataIndex: umd.fields.is_admin.name,
      key: umd.fields.is_admin.name,
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: umd.fields.tenant.label,
      dataIndex: umd.fields.tenant.name,
      key: umd.fields.tenant.name,
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: umd.fields.roles.label,
      dataIndex: umd.fields.roles.name,
      key: umd.fields.roles.name,
      render: (text, record) => (
        <>
          {record.roles.map(({ name }) => (
            <Tag>{name}</Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      key: 'Delete',
      render: (text, record) => (
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this task?"
          onConfirm={async () => {
            await deleteUser({ id: record.id })
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
    {
      title: 'Activate',
      dataIndex: 'Activate',
      key: 'Activate',
      render: (text, record) => (
        <Switch
          defaultChecked
          value={record.is_activated}
          onChange={async () => {
            console.log(record.id)
            await activate({
              email: record.email,
              activatedRequest: { is_activated: !record.is_activated },
            })
          }}
        />
      ),
    },
  ]

  // Assuming data is the array of UserAdminResponse items
  const groupedData = data?.reduce(
    (acc, user) => {
      const tenant = user.tenant || 'No Tenant' // Group users with no tenant

      return acc
    },
    {} as Record<
      string,
      { tenant: string; goodThreadCount: number; goodRunCount: number }
    >,
  )

  const toxicColumns = [
    {
      title: 'Tenant',
      dataIndex: 'tenant',
      key: 'tenant',
    },
    {
      title: 'Good Threads',
      dataIndex: 'goodThreadCount',
      key: 'goodThreadCount',
    },
    {
      title: 'Good Runs',
      dataIndex: 'goodRunCount',
      key: 'goodRunCount',
    },
  ]

  const tableData = Object.values(groupedData ? groupedData : [])

  return (
    <Page
      title={t(AdminTenantsRoute.title)}
      layoutStyle={{ overflow: 'auto', height: '100%' }}
    >
      <Debug debug objects={{ data }} />
      <Debug debug objects={{ tableData, groupedData }} />
      <Typography.Title level={3}>Toxicity as a virtue</Typography.Title>
      <Table
        columns={toxicColumns}
        dataSource={tableData}
        rowKey="tenant"
        pagination={{
          pageSize: 50,
        }}
      />
    </Page>
  )
}
