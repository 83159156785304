import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setIsPromoOpen } from '../../redux/slices/mainSlice'
import Debug from '../helpers/Debug'

import PlanComparison from './components/PlanComparison'

type Props = {}

export default function SubscriptionForm({}: Props) {
  const { isPromoOpen } = useAppSelector((state) => state.main)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  return (
    <>
      <Debug objects={{ isPromoOpen }} />
      <Modal
        open={isPromoOpen}
        width={1000}
        onCancel={() => dispatch(setIsPromoOpen(false))}
        footer={null}
        // footer={
        //   activeKey === 'payment'
        //     ? [
        //         <Button
        //           key="back"
        //           onClick={() => dispatch(setIsPromoOpen(false))}
        //         >
        //           {t('edu.subscription.modal.actions.cancel')}
        //         </Button>,
        //         <Button key="subscribe" type="primary">
        //           {t('edu.subscription.modal.actions.subscribe')}
        //         </Button>,
        //       ]
        //     : []
        // }
      >
        <PlanComparison />
      </Modal>
    </>
  )
}
