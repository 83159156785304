import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type { SubscriptionRequestBody } from '../../api/edu/subscription'
export const getSubscriptionModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<SubscriptionRequestBody>(
    {
      plan_id: {},
      phone_number: {},
    },
    t,
    'edu.subscription',
  )
  return nd
}
