import { Form, Select } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { FormItemProps, SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

import type { FormElementProps } from './helpers'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  options: DefaultOptionType[]
} & SelectProps &
  FormElementProps

export default function FormSelect({
  itemProps,
  formField,
  options,
  editing = true,
  displayElement,
  ...props
}: Props) {
  const id = useId()
  const { t } = useTranslation()

  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      {editing ? (
        <Select
          {...props}
          placeholder={t(formField.placeholder)}
          allowClear
          options={options}
        />
      ) : (
        displayElement
      )}
    </Form.Item>
  )
}
