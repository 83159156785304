import { authApi as api } from './index'
export const addTagTypes = ['/user'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      userList: build.query<UserListApiResponse, UserListApiArg>({
        query: () => ({ url: `/auth/user/` }),
        providesTags: ['/user'],
      }),
      userUpdate: build.mutation<UserUpdateApiResponse, UserUpdateApiArg>({
        query: (queryArg) => ({
          url: `/auth/user/`,
          method: 'PUT',
          body: queryArg.updateUserRequest,
        }),
        invalidatesTags: ['/user'],
      }),
      userGetMe: build.query<UserGetMeApiResponse, UserGetMeApiArg>({
        query: () => ({ url: `/auth/user/me` }),
        providesTags: ['/user'],
      }),
      userActivate: build.mutation<UserActivateApiResponse, UserActivateApiArg>(
        {
          query: (queryArg) => ({
            url: `/auth/user/activate/${queryArg.email}`,
            method: 'POST',
            body: queryArg.activatedRequest,
          }),
          invalidatesTags: ['/user'],
        }
      ),
      userDelete: build.mutation<UserDeleteApiResponse, UserDeleteApiArg>({
        query: (queryArg) => ({
          url: `/auth/user/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['/user'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as userApi }
export type UserListApiResponse =
  /** status 200 Successful Response */ UserAdminResponse[]
export type UserListApiArg = void
export type UserUpdateApiResponse =
  /** status 200 Successful Response */ UserResponse
export type UserUpdateApiArg = {
  updateUserRequest: UpdateUserRequest
}
export type UserGetMeApiResponse =
  /** status 200 Successful Response */ UserResponse
export type UserGetMeApiArg = void
export type UserActivateApiResponse = /** status 200 Successful Response */ any
export type UserActivateApiArg = {
  email: string
  activatedRequest: ActivatedRequest
}
export type UserDeleteApiResponse = /** status 200 Successful Response */ any
export type UserDeleteApiArg = {
  id: string
}
export type TenantResponse = {
  name: string
  is_active: boolean
}
export type RegionType =
  | '\u0410\u043B\u043C\u0430\u0442\u044B'
  | '\u0410\u0441\u0442\u0430\u043D\u0430'
  | '\u0428\u044B\u043C\u043A\u0435\u043D\u0442'
  | '\u0410\u049B\u043C\u043E\u043B\u0430 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0410\u049B\u0442\u04E9\u0431\u0435 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0410\u043B\u043C\u0430\u0442\u044B \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0410\u0442\u044B\u0440\u0430\u0443 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0428\u044B\u0493\u044B\u0441 \u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u049A\u0430\u0440\u0430\u0493\u0430\u043D\u0434\u044B \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u049A\u043E\u0441\u0442\u0430\u043D\u0430\u0439 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u049A\u044B\u0437\u044B\u043B\u043E\u0440\u0434\u0430 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u041C\u0430\u04A3\u0493\u044B\u0441\u0442\u0430\u0443 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0421\u043E\u043B\u0442\u04AF\u0441\u0442\u0456\u043A \u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u041F\u0430\u0432\u043B\u043E\u0434\u0430\u0440 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0422\u04AF\u0440\u043A\u0456\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0411\u0430\u0442\u044B\u0441 \u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0416\u0430\u043C\u0431\u044B\u043B \u043E\u0431\u043B\u044B\u0441\u044B'
export type SexEnum = 'male' | 'female'
export type FeatureName =
  | 'order_submission'
  | 'expanding_result'
  | 'document_generation'
export type PlanFeatureResponse = {
  id: string
  feature_name: FeatureName
  feature_limit?: number | null
  is_unlimited: boolean
  is_enabled: boolean
  description?: string | null
}
export type PlanResponse = {
  id: string
  name: string
  description: string | null
  price: number | null
  billing_cycle_days: number | null
  is_active: boolean
  is_trial_available: boolean
  trial_days: number | null
  created_by: string | null
  created_at: string
  features?: PlanFeatureResponse[]
}
export type SubscriptionStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'CANCELED'
  | 'EXPIRED'
  | 'PENDING'
export type SubscriptionResponse = {
  id: number
  user_id: string
  plan: PlanResponse
  phone_number: string | null
  status: SubscriptionStatus
  valid_until: string
  start_date: string
}
export type RoleResponse = {
  id: number
  name: string
}
export type UserAdminResponse = {
  id: string
  email: string
  first_name?: string | null
  last_name?: string | null
  is_admin: boolean
  is_activated: boolean
  /** The tenant associated with the user. */
  tenant?: TenantResponse | null
  region?: RegionType | null
  date_of_birth?: string | null
  sex?: SexEnum | null
  school?: string | null
  assistant_id?: string | null
  subscription?: SubscriptionResponse | null
  /** List of roles associated with the user */
  roles: RoleResponse[]
  password?: string | null
}
export type UserResponse = {
  id: string
  email: string
  first_name?: string | null
  last_name?: string | null
  is_admin: boolean
  is_activated: boolean
  /** The tenant associated with the user. */
  tenant?: TenantResponse | null
  region?: RegionType | null
  date_of_birth?: string | null
  sex?: SexEnum | null
  school?: string | null
  assistant_id?: string | null
  subscription?: SubscriptionResponse
  /** List of roles associated with the user */
  roles: RoleResponse[]
  password?: string | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type UpdateUserRequest = {
  /** First name of the user */
  first_name?: string | null
  /** Last name of the user */
  last_name?: string | null
  /** Region of the user */
  region?: RegionType | null
  /** Date of birth of the user */
  date_of_birth?: string | null
  /** School of the user */
  school?: string | null
  sex?: SexEnum | null
}
export type ActivatedRequest = {
  is_activated: boolean
}
export const {
  useUserListQuery,
  useLazyUserListQuery,
  useUserUpdateMutation,
  useUserGetMeQuery,
  useLazyUserGetMeQuery,
  useUserActivateMutation,
  useUserDeleteMutation,
} = injectedRtkApi
