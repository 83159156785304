import type { TPlan } from '../../../components/SubscriptionForm/components/PlanCard'
import PlanCard from '../../../components/SubscriptionForm/components/PlanCard'

type Props = {
  plan: TPlan
}

export default function PaymentDescription({ plan }: Props) {
  return <PlanCard plan={plan} />
}
