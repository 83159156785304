import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type { CategoryBody, CategoryResponse } from '../../api/edu/category'
export const getCategoryModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<CategoryBody & CategoryResponse>(
    {
      id: {},
      title: {},
      teachers_discipline_id: {},
      subcategories: {},
    },
    t,
    'edu.category',
  )
  return nd
}
