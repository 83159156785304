import { eduApi as api } from './index'
export const addTagTypes = ['order'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      orderGetDailyUsageStatistics: build.query<
        OrderGetDailyUsageStatisticsApiResponse,
        OrderGetDailyUsageStatisticsApiArg
      >({
        query: () => ({ url: `/edu/order/usage-statistics` }),
        providesTags: ['order'],
      }),
      orderLast: build.query<OrderLastApiResponse, OrderLastApiArg>({
        query: () => ({ url: `/edu/order/order-last` }),
        providesTags: ['order'],
      }),
      orderSubmit: build.mutation<OrderSubmitApiResponse, OrderSubmitApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}/submit`,
          method: 'POST',
        }),
        invalidatesTags: ['order'],
      }),
      orderRenderResultToFile: build.mutation<
        OrderRenderResultToFileApiResponse,
        OrderRenderResultToFileApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}/render-result`,
          method: 'POST',
        }),
        invalidatesTags: ['order'],
      }),
      orderExpandResult: build.mutation<
        OrderExpandResultApiResponse,
        OrderExpandResultApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}/expand-result`,
          method: 'POST',
        }),
        invalidatesTags: ['order'],
      }),
      orderCreate: build.mutation<OrderCreateApiResponse, OrderCreateApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/`,
          method: 'POST',
          body: queryArg.orderBody,
        }),
        invalidatesTags: ['order'],
      }),
      orderList: build.query<OrderListApiResponse, OrderListApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/`,
          params: {
            subcategory_id: queryArg.subcategoryId,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ['order'],
      }),
      orderGet: build.query<OrderGetApiResponse, OrderGetApiArg>({
        query: (queryArg) => ({ url: `/edu/order/${queryArg.orderId}` }),
        providesTags: ['order'],
      }),
      orderUpdate: build.mutation<OrderUpdateApiResponse, OrderUpdateApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}`,
          method: 'PUT',
          body: queryArg.orderUpdateBody,
        }),
        invalidatesTags: ['order'],
      }),
      orderDelete: build.mutation<OrderDeleteApiResponse, OrderDeleteApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['order'],
      }),
      orderSoftDelete: build.mutation<
        OrderSoftDeleteApiResponse,
        OrderSoftDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/order/soft/${queryArg.orderId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['order'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as orderApi }
export type OrderGetDailyUsageStatisticsApiResponse =
  /** status 200 Successful Response */ UsageStatisticsResponse
export type OrderGetDailyUsageStatisticsApiArg = void
export type OrderLastApiResponse =
  /** status 200 Successful Response */ OrderResponse | null
export type OrderLastApiArg = void
export type OrderSubmitApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderSubmitApiArg = {
  orderId: string
}
export type OrderRenderResultToFileApiResponse =
  /** status 200 Successful Response */ any
export type OrderRenderResultToFileApiArg = {
  orderId: string
}
export type OrderExpandResultApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderExpandResultApiArg = {
  orderId: string
}
export type OrderCreateApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderCreateApiArg = {
  orderBody: OrderBody
}
export type OrderListApiResponse =
  /** status 200 Successful Response */ OrderResponse[]
export type OrderListApiArg = {
  subcategoryId: string
  limit?: number
  offset?: number
}
export type OrderGetApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderGetApiArg = {
  orderId: string
}
export type OrderUpdateApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderUpdateApiArg = {
  orderId: string
  orderUpdateBody: OrderUpdateBody
}
export type OrderDeleteApiResponse = /** status 200 Successful Response */ any
export type OrderDeleteApiArg = {
  orderId: string
}
export type OrderSoftDeleteApiResponse =
  /** status 200 Successful Response */ any
export type OrderSoftDeleteApiArg = {
  orderId: string
}
export type UsageStatistics = {
  used: number
  remaining: number
  upgradable?: boolean
}
export type UsageStatisticsResponse = {
  usage: {
    [key: string]: UsageStatistics
  }
}
export type TaskType =
  | 'Questions to be researched'
  | 'Test generation'
  | 'Essay writing'
  | 'Quiz creation'
  | 'Material preparation'
  | 'Lesson planning'
  | 'Pedagogical advice'
  | 'Analysis and estimation'
export type ComplexityLevel =
  | 'Beginner'
  | 'Intermediate'
  | 'Advanced'
  | 'Expert'
export type Language = 'Kazakh' | 'Russian' | 'English'
export type OrderStatus =
  | 'Created'
  | 'In Progress'
  | 'Completed'
  | 'Failed'
  | 'Deleted'
export type OrderResponse = {
  /** A concise name for the order. */
  title: string
  /** Discipline associated with the order. */
  teachers_discipline_id: string
  /** Category ID within the discipline. */
  category_id: string
  /** Subcategory ID within the category. */
  subcategory_id: string
  /** Type of task for the order. */
  task_type: TaskType
  /** Complexity level of the task. */
  level?: ComplexityLevel
  /** Language in which the LLM should respond. */
  response_language?: Language
  /** User Instruction(query) */
  instruction: string
  /** Optional input text relevant to the order. */
  input_text?: string | null
  /** MongoDB uuid as a string */
  _id: string
  user_id: string
  /** Timestamp when the order was created. */
  created_at: string
  status: OrderStatus
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type OrderBody = {
  /** A concise name for the order. */
  title: string
  /** Discipline associated with the order. */
  teachers_discipline_id: string
  /** Category ID within the discipline. */
  category_id: string
  /** Subcategory ID within the category. */
  subcategory_id: string
  /** Type of task for the order. */
  task_type: TaskType
  /** Complexity level of the task. */
  level?: ComplexityLevel
  /** Language in which the LLM should respond. */
  response_language?: Language
  /** User Instruction(query) */
  instruction: string
  /** Optional input text relevant to the order. */
  input_text?: string | null
}
export type OrderUpdateBody = {
  /** Discipline associated with the order. */
  teachers_discipline_id?: string | null
  /** Category ID within the discipline. */
  category_id?: string | null
  /** Subcategory ID within the category. */
  subcategory_id?: string | null
  /** A concise name for the order. */
  title?: string | null
  /** User Instruction(query) */
  instruction?: string | null
  /** Optional input text relevant to the order. */
  input_text?: string | null
  /** Type of task for the order. */
  task_type?: TaskType | null
  /** Complexity level of the task. */
  level?: ComplexityLevel | null
  /** The language in which the response or result is generated. */
  response_language?: Language | null
  /** Additional metadata for the order. */
  metadata?: object | null
  /** Has no effect, will be reset by BE. */
  temperature?: number | null
}
export const {
  useOrderGetDailyUsageStatisticsQuery,
  useLazyOrderGetDailyUsageStatisticsQuery,
  useOrderLastQuery,
  useLazyOrderLastQuery,
  useOrderSubmitMutation,
  useOrderRenderResultToFileMutation,
  useOrderExpandResultMutation,
  useOrderCreateMutation,
  useOrderListQuery,
  useLazyOrderListQuery,
  useOrderGetQuery,
  useLazyOrderGetQuery,
  useOrderUpdateMutation,
  useOrderDeleteMutation,
  useOrderSoftDeleteMutation,
} = injectedRtkApi
