export default {
  fields: {
    discipline_id: {
      label: 'Order',
      placeholder: 'Enter order',
    },
    title: {
      label: 'Title',
      placeholder: 'Enter title',
    },
    instruction: {
      label: 'Instructions',
      placeholder: 'Enter instructions',
      default: {
        'Questions to be researched': `<TEMPLATE>
Questions to be researched en
</TEMPLATE>`,
        'Test generation': `<TEMPLATE>
Test generation en
</TEMPLATE>`,
        'Essay writing': `<TEMPLATE>
Essay writing en
</TEMPLATE>`,
        'Quiz creation': `<TEMPLATE>
Quiz creation en
</TEMPLATE>`,
        'Material preparation': `<TEMPLATE>
Material preparation en
</TEMPLATE>`,
        'Lesson planning': `<TEMPLATE>
Lesson planning en
</TEMPLATE>`,
        'Pedagogical advice': `<TEMPLATE>
Pedagogical advice en
</TEMPLATE>`,
        'Analysis and estimation': `<TEMPLATE>
Analysis and estimation en
</TEMPLATE>`,
      },
    },
    input_text: {
      label: 'Text for AI',
      placeholder: 'Enter the text to be considered by AI...',
    },
    result_text: {
      label: 'AI Result',
      placeholder: 'Enter AI Result',
    },
    task_type: {
      label: 'Task type',
      placeholder: 'Select task type',
      tag: '{{task_type}}',
      options: {
        'Test generation': 'Test generation',
        'Quiz creation': 'Quiz creation',
        'Questions to be researched': 'Questions to be researched',
        'Pedagogical advice': 'Pedagogical advice',
        'Essay writing': 'Essay writing',
        'Material preparation': 'Material preparation',
        'Analysis and estimation': 'Analysis and estimation',
        'Lesson planning': 'Lesson planning',
      },
    },
    status: {
      label: 'Status',
      tag: '{{status}}',
      placeholder: 'Select status',
      options: {
        'In Progress': 'In Progress',
        Completed: 'Completed',
        Deleted: 'Deleted',
        Failed: 'Failed',
        Created: 'Created',
      },
    },
    response_language: {
      label: 'Language',
      placeholder: 'Select language',
      options: {
        English: 'English',
        Russian: 'Russian',
        Kazakh: 'Kazakh',
      },
    },
    level: {
      label: 'Level',
      placeholder: 'Select level',
      options: {
        Advanced: 'Advanced',
        Beginner: 'Beginner',
        Expert: 'Expert',
        Intermediate: 'Intermediate',
      },
    },
    category_id: {
      label: 'Category',
    },
    sub_category_id: {
      label: 'Subcategory',
    },
    teachers_discipline_id: {
      label: 'Discipline',
    },
  },
  form: {
    title: {
      create: 'Create order',
      update: 'Update order',
    },
    toast: {
      updated: 'Order was successfully updated',
      created: 'Order was successfully created',
      deleted: 'Order was successfully deleted',
    },
    actions: {
      create: 'New order',
      save: 'Save',
      edit: 'Edit',
      generateDocx: {
        title: 'DOCX',
        finishGeneration: 'DOCX generation. Please finish response generation.',
        limitExceeded:
          'DOCX generation. You have reached the limit ({{ limit }} used).',
      },
      approve: {
        title: 'Submit',
        hint: 'To submit the order please press green button at the bottom right of the screen',
        alreadyRun: 'Order already submitted',
        limitExceeded: 'You have reached the limit ({{ limit }} used).',
      },
      expand: {
        title: 'Improve',
        finishGeneration: 'Improve answer. Please finish response generation.',
        limitExceeded:
          'Improve answer. You have reached the limit ({{ limit }} used).',
      },
      regenerate: {
        title: 'Generate again',
        finishGeneration: 'Generate again. Please finish response generation.',
        limitExceeded:
          'Generate again. You have reached the limit ({{ limit }} used).',
      },
      delete: {
        title: 'Delete',
        popConfirm: {
          title: 'Delete the order',
          description: 'Are you sure to delete this order?',
        },
      },
      like: 'Like',
    },
  },
}
