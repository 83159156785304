import { CheckOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import type { TFunction } from 'i18next'
import type { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAppDispatch } from '../../../app/hooks'
import type {
  PlanFeatureResponse,
  PlanResponse,
} from '../../../redux/api/auth/auth'
import type { PlanListApiResponse } from '../../../redux/api/edu/plan'
import { setIsPromoOpen } from '../../../redux/slices/mainSlice'
import { params, paths } from '../../../routes/paths'
import Debug from '../../helpers/Debug'

import { SubscriptionFeatures } from './SubscriptionFeatures'

export type TPlan = {
  id: string
  name: string
  title: string
  description: string | null
  price?: string
  buyBtnText: string
  features?: PlanFeatureResponse[]
}

export const transformPlan = (
  {
    billing_cycle_days,
    created_at,
    created_by,
    description,
    id,
    is_active,
    is_trial_available,
    name,
    price,
    trial_days,
    features,
  }: PlanResponse,
  t: TFunction,
) => ({
  id,
  name,
  buyBtnText: t(`edu.subscription.plans.${name}.buy`),
  features,
  description,
  title: t(`edu.subscription.plans.${name}.title`),
  price: price ? t('edu.subscription.rate.monthly.kzt', { price }) : undefined,
})

export const findPlan = (
  data: PlanListApiResponse | undefined,
  planName: string,
) => data?.find(({ name }) => name === planName)

const { Text } = Typography
type Props = {
  plan: TPlan
  renderButton?: boolean
  selectedPlanId?: string | null
  selectable?: boolean
  onSelect?: (planId: string) => void
}

export default function PlanCard({
  renderButton = false,
  plan,
  selectedPlanId,
  selectable = false,
  onSelect,
}: Props) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const selectableCSS: CSSProperties = selectable
    ? {
        border:
          selectedPlanId === plan.id
            ? '2px solid #1890ff'
            : '1px solid #f0f0f0',
      }
    : {}
  return (
    <Card
      key={plan.id}
      bordered={selectedPlanId === plan.id}
      hoverable={selectable}
      onClick={onSelect ? () => onSelect(plan.id) : undefined}
      style={{
        width: 300,
        textAlign: 'center',
        border: plan.title === 'Professional' ? '2px solid #28a745' : '',
        boxShadow:
          plan.title === 'Ultimate' ? '0 0 10px rgba(0, 123, 255, 0.3)' : '',
        ...selectableCSS,
      }}
      extra={
        selectedPlanId === plan.id ? (
          <CheckOutlined style={{ color: '#1890ff', fontSize: '16px' }} />
        ) : null
      }
    >
      <Title level={4} style={{ height: '60px' }}>
        {plan.title}
      </Title>
      <Debug objects={{ plan }} />
      {plan.description && (
        <Text style={{ marginLeft: '5px' }}>{plan.description}</Text>
      )}
      <Divider />
      <Text style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        {plan.price || t('edu.subscription.rate.free')}
      </Text>
      <Divider />
      {plan.features && <SubscriptionFeatures features={plan.features} />}
      {renderButton && (
        <>
          {plan.name === 'Basic' ? (
            <Button
              type={'default'}
              style={{
                marginTop: '20px',
                width: '100%',
              }}
              onClick={() => dispatch(setIsPromoOpen(false))}
            >
              {plan.buyBtnText}
            </Button>
          ) : (
            <Link to={paths.paymentPlan.replace(params.plan_id, plan.id)}>
              <Button
                type={'primary'}
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: 'var(--color-primary-purple)',
                }}
                onClick={() => dispatch(setIsPromoOpen(false))}
              >
                {plan.buyBtnText}
              </Button>
            </Link>
          )}
        </>
      )}

      {/* <Col key={plan.key} xs={24} sm={12} md={8}>
          <Card
            title={plan.title}
            bordered={selectedPlan === plan.key}
            
            style={{
              
            }}
            onClick={() => handleSelectPlan(plan.key)}
          >
            <p>{plan.description}</p>
            <h3>{plan.price}</h3>
            <Button
              type={selectedPlan === plan.key ? 'primary' : 'default'}
              style={{ marginTop: '10px' }}
              onClick={() => handleSelectPlan(plan.key)}
            >
              {selectedPlan === plan.key ? 'Selected' : 'Choose Plan'}
            </Button>
          </Card>
        </Col> */}
    </Card>
  )
}
