import { CrownFilled } from '@ant-design/icons'
import { Badge, Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '../app/hooks'
import { setIsPromoOpen } from '../redux/slices/mainSlice'

import Iconify from './Iconify'

type Props = {}

export default function UpgradeButton({}: Props) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  return (
    <Tooltip title={t('edu.subscription.plans.upgrade')}>
      <Badge
        offset={[-2, 2]}
        count={
          <CrownFilled
            style={{
              color: 'green',
            }}
          />
        }
      >
        <Button
          onClick={(e) => {
            dispatch(setIsPromoOpen(true))
          }}
          icon={
            <Iconify
              icon={'carbon:intent-request-upgrade'}
              style={{ fontSize: 18 }}
            />
          }
          size="middle"
          style={{
            background: 'gold',
          }}
          type="text"
        ></Button>
      </Badge>
    </Tooltip>
  )
}
