import type { NavigateFunction } from 'react-router-dom'

import { params, paths } from '../routes/paths'
type T = 'discipline' | 'category' | 'subCategory' | 'order'

export const getOnSelect =
  (
    navigate: NavigateFunction,
    teachers_discipline_id: string | undefined,
    category_id: string | undefined,
    subcategory_id: string | undefined,
  ) =>
  (asd: T) => {
    console.log(asd)
    return onSelect(
      asd,
      navigate,
      teachers_discipline_id,
      category_id,
      subcategory_id,
    )
  }

const onSelect =
  (
    type: T,
    navigate: NavigateFunction,
    teachers_discipline_id: string | undefined,
    category_id: string | undefined,
    subcategory_id: string | undefined,
  ) =>
  (val: string | undefined) => {
    console.log(val)
    if (type === 'discipline') {
      if (val)
        navigate(
          paths.eduPlayground1.replace(params.teachers_discipline_id, val),
        )
      else navigate(paths.eduPlayground)
    } else if (teachers_discipline_id) {
      if (type === 'category') {
        console.log(teachers_discipline_id, val)
        if (val)
          navigate(
            paths.eduPlayground2
              .replace(params.teachers_discipline_id, teachers_discipline_id)
              .replace(params.category_id, val),
          )
        else
          navigate(
            paths.eduPlayground1.replace(
              params.teachers_discipline_id,
              teachers_discipline_id,
            ),
          )
      } else if (category_id) {
        if (type === 'subCategory') {
          if (val)
            navigate(
              paths.eduPlayground3
                .replace(params.teachers_discipline_id, teachers_discipline_id)
                .replace(params.category_id, category_id)
                .replace(params.subcategory_id, val),
            )
          else
            navigate(
              paths.eduPlayground2
                .replace(params.teachers_discipline_id, teachers_discipline_id)
                .replace(params.category_id, category_id),
            )
        } else if (subcategory_id) {
          if (val)
            navigate(
              paths.eduPlayground4
                .replace(params.teachers_discipline_id, teachers_discipline_id)
                .replace(params.category_id, category_id)
                .replace(params.subcategory_id, subcategory_id)
                .replace(params.order_id, val),
            )
          else
            navigate(
              paths.eduPlayground3
                .replace(params.teachers_discipline_id, teachers_discipline_id)
                .replace(params.category_id, category_id)
                .replace(params.subcategory_id, subcategory_id),
            )
        }
      }
    }
  }
