import { InputMask } from '@react-input/mask'
import type { FormItemProps, InputRef } from 'antd'
import { Input as ANTDInput, Form } from 'antd'
import type { InputProps } from 'antd/lib/input'
import { forwardRef, useId, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

interface CustomAntdInputProps {
  label?: string
  /**
   * These two props come from AntD `Form.Item` for controlled components
   */
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}
const CustomAntdInput = forwardRef<HTMLInputElement, CustomAntdInputProps>(
  ({ label, value, onChange }, forwardedRef) => {
    // ref to the AntD Input (which is an InputRef)
    const antdRef = useRef<InputRef>(null)

    // Expose the *real DOM input* to parent refs
    useImperativeHandle(forwardedRef, () => {
      // `antdInputRef.current` is an InputRef
      // the actual DOM input is `antdInputRef.current?.input`
      return antdRef.current?.input as HTMLInputElement
    })

    return (
      <ANTDInput
        id="custom-input"
        placeholder="+7 "
        ref={antdRef}
        value={value}
        onChange={onChange}
      />
    )
  },
)
const validKZMobilePrefixes = [
  '700',
  '701',
  '702',
  '703',
  '704',
  '705',
  '706',
  '707',
  '708',
  '709',
  '747',
  '750',
  '751',
  '760',
  '761',
  '762',
  '763',
  '764',
  '771',
  '775',
  '776',
  '777',
  '778',
]

interface Props {
  itemProps?: FormItemProps
  inputProps?: InputProps
  // https://icon-sets.iconify.design/
  formField: FormField<any>
}
export default function PhoneNumberInput({
  formField,
  itemProps = {},
  inputProps = {},
}: Props) {
  const { t } = useTranslation()
  const id = useId()
  return (
    <Form.Item
      id={id}
      name={formField?.name}
      validateTrigger="onBlur"
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
      hasFeedback={true}
      rules={[
        ...formField?.rules,
        {
          validator: async (_, value: string) => {
            if (!value) {
              // Let the 'required' rule handle emptiness, so just resolve.
              return Promise.resolve()
            }
            if (!value.startsWith('+7')) {
              // Return a rejected Promise or throw an Error
              return Promise.reject(
                new Error('Phone number must start with +7'),
              )
            }

            // digitsOnly: e.g. "+7 (701) 123-45-67" -> "77011234567"
            const digitsOnly = value.replace(/\D/g, '') // remove non-digits

            if (digitsOnly.length < 11) {
              return Promise.reject(
                new Error(
                  'Phone number must have 11 digits (including the leading 7).',
                ),
              )
            }

            // Next 3 digits after the initial '7'
            const prefix = digitsOnly.slice(1, 4)
            if (!validKZMobilePrefixes.includes(prefix)) {
              return Promise.reject(
                new Error(
                  `Invalid operator prefix: ${prefix}. Must be a valid KZ prefix like 701, 777, etc.`,
                ),
              )
            }

            // If all checks pass, resolve
            return Promise.resolve()
          },
        },
      ]}
    >
      <InputMask
        mask="+7 (___) ___-__-__"
        replacement={{ _: /\d/ }}
        component={CustomAntdInput}
      />
    </Form.Item>
  )
}
