export default {
  fields: {
    discipline_id: {
      label: 'Заказ',
      placeholder: 'Введите заказ',
    },
    title: {
      label: 'Название',
      placeholder: 'Введите название',
    },
    instruction: {
      label: 'Инструкции',
      placeholder: 'Введите инструкции',
      default: {
        'Questions to be researched': `<TEMPLATE>
Questions to be researched ru
</TEMPLATE>
`,
        'Test generation': `<TEMPLATE>
Test generation
</TEMPLATE>`,
        'Essay writing': `<TEMPLATE>
Essay writing ru
</TEMPLATE>`,
        'Quiz creation': `<TEMPLATE>
Quiz creation ru
</TEMPLATE>`,
        'Material preparation': `<TEMPLATE>
Material preparation ru
</TEMPLATE>`,
        'Lesson planning': `<TEMPLATE>
Lesson planning ru
</TEMPLATE>`,
        'Pedagogical advice': `<TEMPLATE>
Pedagogical advice ru
</TEMPLATE>`,
        'Analysis and estimation': `<TEMPLATE>
Analysis and estimation ru
</TEMPLATE>`,
      },
    },
    input_text: {
      label: 'Tекст для AI',
      placeholder: 'Введите текст, который должен быть учтен AI...',
    },
    result_text: {
      label: 'Результат AI',
      placeholder: 'Введите результат AI',
    },
    task_type: {
      label: 'Тип задания',
      placeholder: 'Выберите тип задания',
      tag: '{{task_type}}',
      options: {
        'Quiz creation': 'Создание викторины',
        'Test generation': 'Создание теста',
        'Analysis and estimation': 'Анализ и оценка',
        'Questions to be researched': 'Исследуемые вопросы',
        'Pedagogical advice': 'Педагогический совет',
        'Essay writing': 'Написание эссе',
        'Lesson planning': 'Планирование урока',
        'Material preparation': 'Подготовка материалов',
      },
    },
    status: {
      label: 'Статус',
      tag: '{{status}}',
      placeholder: 'Выберите статус',
      options: {
        'In Progress': 'В процессе',
        Completed: 'Завершено',
        Deleted: 'Удалено',
        Failed: 'Не удалось',
        Created: 'Создано',
      },
    },
    response_language: {
      label: 'Язык',
      placeholder: 'Выберите язык',
      options: {
        English: 'Английский',
        Russian: 'Русский',
        Kazakh: 'Казахский',
      },
    },
    level: {
      label: 'Уровень',
      placeholder: 'Выберите уровень',
      options: {
        Advanced: 'Продвинутый',
        Beginner: 'Начинающий',
        Expert: 'Эксперт',
        Intermediate: 'Средний',
      },
    },
    category_id: {
      label: 'Категория',
    },
    sub_category_id: {
      label: 'Подкатегория',
    },
    teachers_discipline_id: {
      label: 'Дисциплина',
    },
  },
  form: {
    title: {
      create: 'Создать заказ',
      update: 'Обновить заказ',
    },
    toast: {
      updated: 'Заказ успешно обновлен',
      created: 'Заказ успешно создан',
      deleted: 'Заказ успешно удален',
    },
    actions: {
      create: 'Новый заказ',
      save: 'Сохранить',
      edit: 'Редактировать',
      generateDocx: {
        title: 'DOCX',
        finishGeneration:
          'Генерация DOCX. Пожалуйста, завершите генерацию ответа.',
        limitExceeded:
          'Генерация DOCX. Вы достигли лимита (использовано {{ limit }}).',
      },
      approve: {
        title: 'Отправить',
        hint: 'Для обработки задачи нажмите зеленую кнопку в правом нижнем углу экрана',
        alreadyRun: 'Заказ уже отправлен',
        limitExceeded: 'Вы достигли лимита (использовано {{ limit }}).',
      },
      expand: {
        title: 'Расширить',
        finishGeneration:
          'Улучшение ответа. Пожалуйста, завершите генерацию ответа.',
        limitExceeded:
          'Улучшение ответа. Вы достигли лимита (использовано {{ limit }}).',
      },
      regenerate: {
        title: 'Перегенерировать',
        finishGeneration:
          'Перегенерировать. Пожалуйста, завершите генерацию ответа.',
        limitExceeded:
          'Перегенерировать. Вы достигли лимита (использовано {{ limit }}).',
      },
      delete: {
        title: 'Удалить',
        popConfirm: {
          title: 'Удалить заказ',
          description: 'Вы уверены, что хотите удалить этот заказ?',
        },
      },
      like: 'Оценить',
    },
  },
}
