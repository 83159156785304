import type { FormItemProps } from 'antd'
import type { SelectProps } from 'antd/lib'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import type { TSupportedLangs } from '../../i18n'
import type { Language } from '../../redux/api/edu/order'
import type { FormField } from '../../utils/modelDescriptor/formFields'

import type { FormElementProps } from './helpers'
import FormSelect from './Select'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  selectProps?: SelectProps
} & FormElementProps

export const mapLanguage = (key: Language, t: TFunction) => {
  const languageMapping: {
    [k in Language]: string
  } = {
    Kazakh: t('edu.order.fields.response_language.options.Kazakh'),
    Russian: t('edu.order.fields.response_language.options.Russian'),
    English: t('edu.order.fields.response_language.options.English'),
  }
  return languageMapping[key]
}
export const mapLanguageCode = (key: Language): TSupportedLangs => {
  const languageMapping: {
    [k in Language]: TSupportedLangs
  } = {
    English: 'en-US',
    Kazakh: 'kk-KZ',
    Russian: 'ru-RU',
  }
  return languageMapping[key]
}

export default function LanguageSelect({
  formField,
  itemProps,
  selectProps,
  ...rest
}: Props) {
  const { t } = useTranslation()

  return (
    <FormSelect
      formField={formField}
      itemProps={{
        required: false,
        ...itemProps,
      }}
      options={Object.entries({
        Kazakh: mapLanguage('Kazakh', t),
        Russian: mapLanguage('Russian', t),
        English: mapLanguage('English', t),
      }).map(([k, v]) => ({
        key: k,
        value: k,
        label: v,
      }))}
      {...selectProps}
      {...rest}
    />
  )
}
