import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  BodyLoginAuthAuthLoginPost,
  SignUpUserBody,
  UserResponse,
} from '../../api/auth/auth'
import type { UpdateUserRequest, UserAdminResponse } from '../../api/auth/user'
// ----------------------------------------------------------------------

export interface AuthState {
  user: UserResponse | null
  accessToken: string | null
  refreshToken: string | null
}

const initialState: AuthState = {
  user: null,
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
}

export const authSlice = createSlice({
  name: 'auth/auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        user: UserResponse | undefined
        accessToken: string | undefined
        refreshToken: string | undefined
      }>,
    ) => {
      console.log(action)
      const { accessToken, refreshToken, user } = action.payload
      if (user) state.user = user

      if (accessToken) {
        state.accessToken = accessToken
        localStorage.setItem('accessToken', accessToken)
      }

      if (refreshToken) {
        state.refreshToken = refreshToken
        localStorage.setItem('refreshToken', refreshToken)
      }
    },
    removeUser: (state) => {
      state.user = null
      state.accessToken = null
      state.refreshToken = null

      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },
  },
})

export const getUserModelDescriptor = (
  t: TFunction,
  formPrefix: 'form' | 'loginForm' | 'signUpForm' | 'forgotForm' = 'form',
) => {
  const nd = new ModelDescriptor<
    BodyLoginAuthAuthLoginPost & SignUpUserBody & UpdateUserRequest,
    | keyof BodyLoginAuthAuthLoginPost
    | keyof SignUpUserBody
    | keyof UserResponse
    | keyof UserAdminResponse
  >(
    {
      username: {},
      first_name: {},
      last_name: {},
      password: { rules: { required: true } },
      email: { rules: { required: true } },
      client_id: {},
      client_secret: {},
      grant_type: {},
      scope: {},
      id: {},
      assistant_id: {},
      roles: {},
      is_admin: {},
      is_activated: {},
      tenant: {},
      region: {},
      date_of_birth: {},
      sex: {},
      school: {},
      subscription: {},
    },
    t,
    'auth.user',
    formPrefix,
  )
  return nd
}

export const { setUser, removeUser } = authSlice.actions
export default authSlice.reducer
