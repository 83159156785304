import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import type { FormInstance } from 'antd/lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
// ----------------------------------------------------------------------

import { useAppSelector } from '../../../app/hooks'
import FormDatePicker from '../../../components/Form/DatePicker'
import type { IFormBody } from '../../../components/Form/helpers'
import RegionSelect from '../../../components/Form/RegionSelect'
import SexSelect from '../../../components/Form/SexSelect'
import Debug from '../../../components/helpers/Debug'
import type { SignUpUserBody } from '../../../redux/api/auth/auth'
import { getUserModelDescriptor } from '../../../redux/slices/auth/userSlice'
import { DefaultPath } from '../../../routes/paths'

import { TOC } from './TOC'
// ----------------------------------------------------------------------

type Props = {
  form: FormInstance<IFormBody<Required<SignUpUserBody>, never>>
  onSuccess: () => void
  onLogin: () => void
  isTOCAccepted: boolean
  isLoading: boolean
  setIsTOCAccepted: (a: boolean) => void
  signUp: (key: { signUpUserBody: SignUpUserBody }) => Promise<void>
}

export default function SignUpForm({
  isTOCAccepted,
  isLoading,
  onSuccess,
  form,
  signUp,
  onLogin,
  setIsTOCAccepted,
}: Props) {
  const { t } = useTranslation()
  const { accessToken } = useAppSelector((state) => state.auth)
  const signUpMD = getUserModelDescriptor(t, 'signUpForm')
  const [open, setOpen] = useState<boolean>(false)

  const values = Form.useWatch([], form)

  if (accessToken) {
    return <Navigate to={DefaultPath} />
  }

  const onFinish = async (values: Required<SignUpUserBody>) => {
    const signUpUserBody: SignUpUserBody = {
      email: values.email,
      password: values.password,
      tenant: values.tenant,
      date_of_birth: values.date_of_birth
        ? new Date(values.date_of_birth).toISOString().split('T')[0]
        : undefined,
      first_name: values.first_name,
      last_name: values.last_name,
      region: values.region,
      school: values.school,
      sex: values.sex,
    }

    try {
      await signUp({ signUpUserBody })
      signUpMD.toast.created()
      form.resetFields()
      onSuccess()
      setIsTOCAccepted(false)
    } catch (error) {
      // toast.error('Не правильно введен логин или пароль')
      console.error((error as any).data.detail)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Row>
      <Col span={24}>
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          disabled={isLoading}
          form={form}
          initialValues={{
            email: '',
            password: '',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Debug debug objects={{ obj: values }} />
          <Form.Item
            name={signUpMD.fields.email.name}
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            required={true}
            label={signUpMD.fields.email.label}
            rules={[...signUpMD.fields.email.rules]}
          >
            <Input placeholder={t(signUpMD.fields.email.placeholder)} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            label={signUpMD.fields.password.label}
            required
            name={signUpMD.fields.password.name}
            rules={signUpMD.fields.password.rules}
          >
            <Input.Password
              placeholder={t(signUpMD.fields.password.placeholder)}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            label={signUpMD.fields.tenant.label}
            required={false}
            name={signUpMD.fields.tenant.name}
            rules={signUpMD.fields.tenant.rules}
          >
            <Input placeholder={t(signUpMD.fields.tenant.placeholder)} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            label={signUpMD.fields.first_name.label}
            required={false}
            name={signUpMD.fields.first_name.name}
            rules={signUpMD.fields.first_name.rules}
          >
            <Input placeholder={t(signUpMD.fields.first_name.placeholder)} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            label={signUpMD.fields.last_name.label}
            required={false}
            name={signUpMD.fields.last_name.name}
            rules={signUpMD.fields.last_name.rules}
          >
            <Input placeholder={t(signUpMD.fields.last_name.placeholder)} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            label={signUpMD.fields.school.label}
            required={false}
            name={signUpMD.fields.school.name}
            rules={signUpMD.fields.school.rules}
          >
            <Input placeholder={t(signUpMD.fields.school.placeholder)} />
          </Form.Item>
          <FormDatePicker formField={signUpMD.fields.date_of_birth} />
          <SexSelect formField={signUpMD.fields.sex} />
          <RegionSelect formfield={signUpMD.fields.region} />
        </Form>
      </Col>
      <Col span={24}>
        <Checkbox
          value={isTOCAccepted}
          onChange={(e) => {
            setIsTOCAccepted(!isTOCAccepted)
          }}
        >
          <Button
            type="link"
            onClick={() => setOpen(true)}
            style={{ padding: 0 }}
          >
            {t('auth.user.signUpForm.toc.accept')}
          </Button>
        </Checkbox>
      </Col>
      <Col span={24} style={{ paddingTop: 10 }}>
        {t(`${signUpMD.form.action.domain}.actions.login.question`)}
        <Button type="link" size="small" disabled={isLoading} onClick={onLogin}>
          {t(`${signUpMD.form.action.domain}.actions.login.button`)}
        </Button>
      </Col>
      <TOC setIsModalOpen={setOpen} isModalOpen={open} />
    </Row>
  )
}
