import { eduApi as api } from './index'
export const addTagTypes = ['discipline'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      disciplineCreate: build.mutation<
        DisciplineCreateApiResponse,
        DisciplineCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/discipline/`,
          method: 'POST',
          body: queryArg.disciplineCreate,
        }),
        invalidatesTags: ['discipline'],
      }),
      disciplineList: build.query<
        DisciplineListApiResponse,
        DisciplineListApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/discipline/`,
          params: { title: queryArg.title },
        }),
        providesTags: ['discipline'],
      }),
      disciplineRetrieve: build.query<
        DisciplineRetrieveApiResponse,
        DisciplineRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/edu/discipline/${queryArg.id}` }),
        providesTags: ['discipline'],
      }),
      disciplineUpdate: build.mutation<
        DisciplineUpdateApiResponse,
        DisciplineUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/discipline/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.disciplineUpdate,
        }),
        invalidatesTags: ['discipline'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as disciplineApi }
export type DisciplineCreateApiResponse =
  /** status 201 Successful Response */ DisciplineResponse
export type DisciplineCreateApiArg = {
  disciplineCreate: DisciplineCreate
}
export type DisciplineListApiResponse =
  /** status 200 Successful Response */ DisciplineResponse[]
export type DisciplineListApiArg = {
  /** Filter disciplines by title */
  title?: string | null
}
export type DisciplineRetrieveApiResponse =
  /** status 200 Successful Response */ DisciplineResponse
export type DisciplineRetrieveApiArg = {
  id: string
}
export type DisciplineUpdateApiResponse =
  /** status 201 Successful Response */ DisciplineResponse
export type DisciplineUpdateApiArg = {
  id: string
  disciplineUpdate: DisciplineUpdate
}
export type DisciplineResponse = {
  id: string
  title: string
  kz: string
  ru: string
  en: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type DisciplineCreate = {
  title: string
  kz: string
  ru: string
  en: string
}
export type DisciplineUpdate = {
  title?: string | null
  kz?: string | null
  ru?: string | null
  en?: string | null
}
export const {
  useDisciplineCreateMutation,
  useDisciplineListQuery,
  useLazyDisciplineListQuery,
  useDisciplineRetrieveQuery,
  useLazyDisciplineRetrieveQuery,
  useDisciplineUpdateMutation,
} = injectedRtkApi
