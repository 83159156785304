import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Debug from '../../../components/helpers/Debug'
import type { UserResponse } from '../../../redux/api/auth/auth'
import { useOrderGetQuery } from '../../../redux/api/edu/order'
import { useOrderRunListQuery } from '../../../redux/api/edu/order_run'
import { formatEduPlayGroundPath } from '../../../routes/paths'

import PlaygroundBody from './PlaygroundBody'

type Props = {}

export default function BodyWrapper({
  order_id,
  user,
}: {
  order_id: string
  user: UserResponse
}) {
  const { data: order, error } = useOrderGetQuery({
    orderId: order_id || 'asd',
  })
  const { teachers_discipline_id, category_id, subcategory_id } = useParams()
  const navigate = useNavigate()

  const { data: orderRuns } = useOrderRunListQuery({ orderId: order_id })
  useEffect(() => {
    if (error) {
      navigate(
        formatEduPlayGroundPath(
          teachers_discipline_id,
          category_id,
          subcategory_id,
        ),
      )
    }
  }, [error])

  return (
    <>
      <Debug objects={{ order }} />
      {order && (
        <PlaygroundBody order={order} user={user} orderRuns={orderRuns} />
      )}
    </>
  )
}
