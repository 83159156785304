import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Input, Row, Select } from 'antd'
import Title from 'antd/es/typography/Title'
import type { ButtonProps, InputRef } from 'antd/lib'
import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Debug from '../../components/helpers/Debug'
import { languageState } from '../../i18n'
import {
  useCategoryCreateMutation,
  useCategoryListQuery,
} from '../../redux/api/edu/category'
import { useOrderLastQuery } from '../../redux/api/edu/order'
import {
  useSubcategoryCreateMutation,
  useSubcategoryListByCategoryQuery,
} from '../../redux/api/edu/sub_category'
import { useTeacherDisciplinesListQuery } from '../../redux/api/edu/teachers_discipline'
import { getCategoryModelDescriptor } from '../../redux/slices/edu/category'
import { getSubCategoryModelDescriptor } from '../../redux/slices/edu/subCategory'
import { getOnSelect } from '../../utils/onSelect'

import TeacherDisciplineForm from './components/TeacherDisciplineForm'

// import TeacherDisciplineForm from './components/TeacherDisciplineForm'

type Props = {}

const selectStyle: CSSProperties = { width: '100%' }
const AddButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      size="small"
      icon={<PlusOutlined style={{ width: 10 }} />}
      style={{ width: '20px', height: '25px', borderRadius: 5, margin: 3 }}
      {...props}
    />
  )
}

const Wrapper = ({
  children,
  label,
}: {
  children: React.ReactNode
  label: string
}) => {
  return (
    <>
      <Title style={{ margin: 0, paddingLeft: 15 }} level={4}>
        {label}
      </Title>
      <div
        style={{
          // display: 'flex',
          alignItems: 'center',
          borderRadius: 10,
          border: '2px solid #d9d9d9', // Match the default antd border color
          padding: '5px',
          background: '#fff', // Match the background of the Select
          marginInline: '10px',
        }}
      >
        {children}
      </div>
    </>
  )
}

const SubCategorySelect = ({
  category_id,
  subcategory_id,
  teachers_discipline_id,
}: {
  category_id: string
  subcategory_id?: string
  teachers_discipline_id: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onSelect = getOnSelect(
    navigate,
    teachers_discipline_id,
    category_id,
    subcategory_id,
  )

  const [subCategoryTitle, setSubCategoryTitle] = useState('')
  const inputRef = useRef<InputRef>(null)
  const [subCategoryCreate, { isSuccess: subCategoryisSuccess, error }] =
    useSubcategoryCreateMutation()
  const { data: subCategories, isLoading: subCategoriesIsloading } =
    useSubcategoryListByCategoryQuery({ categoryId: category_id })
  const scmd = getSubCategoryModelDescriptor(t)

  useEffect(() => {
    if (error) scmd.toast.error()
  }, [error])

  useEffect(() => {
    if (subCategoryisSuccess) scmd.toast.created()
  }, [subCategoryisSuccess])
  return (
    <Wrapper label={t('edu.order.fields.sub_category_id.label')}>
      <Select
        value={subcategory_id}
        onChange={onSelect('subCategory')}
        style={selectStyle}
        allowClear
        loading={subCategoriesIsloading}
        options={subCategories?.map(
          ({ category_id, id, title, teachers_discipline_id }) => ({
            value: id,
            label: title,
          }),
        )}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <div
              style={{
                padding: '0 8px 4px',
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
              }}
            >
              <Input
                ref={inputRef}
                value={subCategoryTitle}
                style={{
                  width: '100%',
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSubCategoryTitle(event.target.value)
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
                ) => {
                  e.preventDefault()
                  if (category_id && teachers_discipline_id) {
                    const title = subCategoryTitle.trim()
                    if (title.length >= 1) {
                      subCategoryCreate({
                        subcategoryBody: {
                          category_id,
                          teachers_discipline_id: teachers_discipline_id,
                          title,
                        },
                      })
                        .unwrap()
                        .then(({ id }) => onSelect('subCategory')(id))
                    } else {
                      toast.error(
                        t('edu.subCategory.fields.title.errors.short'),
                      )
                    }
                  }
                  setSubCategoryTitle('')
                  setTimeout(() => {
                    inputRef.current?.focus()
                  }, 0)
                }}
              ></Button>
            </div>
          </>
        )}
      />
    </Wrapper>
  )
}

const CategorySelect = ({
  category_id,
  teachers_discipline_id,
  subcategory_id,
}: {
  category_id?: string
  teachers_discipline_id: string
  subcategory_id?: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const cmd = getCategoryModelDescriptor(t)

  const [
    categoryCreate,
    { isSuccess: isCategoryCreateSuccess, isError: isCategoryCreateError },
  ] = useCategoryCreateMutation()
  const onSelect = getOnSelect(
    navigate,
    teachers_discipline_id,
    category_id,
    subcategory_id,
  )
  const [categoryTitle, setCategoryTitle] = useState('')
  const inputRef = useRef<InputRef>(null)
  const { data: categories, isLoading: categoriesIsLoading } =
    useCategoryListQuery({
      teachersDisciplineId: teachers_discipline_id,
    })

  useEffect(() => {
    if (isCategoryCreateError) cmd.toast.error()
  }, [isCategoryCreateError])

  useEffect(() => {
    if (isCategoryCreateSuccess) cmd.toast.created()
  }, [isCategoryCreateSuccess])

  return (
    <Wrapper label={t('edu.order.fields.category_id.label')}>
      <Select
        value={category_id}
        allowClear
        loading={categoriesIsLoading}
        onChange={onSelect('category')}
        style={selectStyle}
        options={categories?.map(({ id, title }) => ({
          value: id,
          label: title,
        }))}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <div
              style={{
                padding: '0 8px 4px',
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
              }}
            >
              <Input
                ref={inputRef}
                value={categoryTitle}
                style={{
                  width: '100%',
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCategoryTitle(event.target.value)
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={async (
                  e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
                ) => {
                  e.preventDefault()
                  if (teachers_discipline_id) {
                    const title = categoryTitle.trim()
                    if (title.length >= 1) {
                      await categoryCreate({
                        categoryBody: {
                          teachers_discipline_id: teachers_discipline_id,
                          title: categoryTitle,
                        },
                      })
                        .unwrap()
                        .then(({ id }) => onSelect('category')(id))
                    } else
                      toast.error(t('edu.category.fields.title.errors.short'))
                  }

                  setCategoryTitle('')
                  setTimeout(() => {
                    inputRef.current?.focus()
                  }, 0)
                }}
              />
            </div>
          </>
        )}
      />
    </Wrapper>
  )
}

export default function PlayGroundAppBar({}: Props) {
  const { t } = useTranslation()

  const { data: teacherDisciplines, isLoading: teacherDisciplinesIsLoading } =
    useTeacherDisciplinesListQuery()

  const navigate = useNavigate()

  const { teachers_discipline_id, category_id, subcategory_id, order_id } =
    useParams()
  const [teacherDisciplineFormOpen, setTeacherDisciplineFormOpen] =
    useState(false)

  const { data: lastOrder } = useOrderLastQuery()
  const onSelect = getOnSelect(
    navigate,
    teachers_discipline_id,
    category_id,
    subcategory_id,
  )

  useEffect(() => {
    if (lastOrder) {
      const onLastSelect = getOnSelect(
        navigate,
        lastOrder.teachers_discipline_id,
        lastOrder.category_id,
        lastOrder.subcategory_id,
      )
      onLastSelect('order')(lastOrder._id)
    }
  }, [lastOrder])

  return (
    <Row style={{ marginBlock: 15 }}>
      <Col span={8} xs={24} md={8}>
        <Debug objects={{ lastOrder }} />
        <Wrapper label={t('edu.order.fields.teachers_discipline_id.label')}>
          <div style={{ display: 'flex' }}>
            <Select
              // defaultValue="lucy"
              style={selectStyle}
              value={teachers_discipline_id}
              allowClear
              onChange={onSelect('discipline')}
              loading={teacherDisciplinesIsLoading}
              options={teacherDisciplines?.map(
                ({
                  id,
                  discipline: { en, kz, ru, id: disciplineId, title },
                  user_id,
                  categories,
                }) => ({
                  value: id,
                  key: id,
                  label:
                    languageState === 'kk-KZ'
                      ? kz
                      : languageState === 'ru-RU'
                        ? ru
                        : en,
                }),
              )}
            />
            <AddButton onClick={() => setTeacherDisciplineFormOpen(true)} />
          </div>
        </Wrapper>
      </Col>
      <Col span={8} xs={24} md={8}>
        {teachers_discipline_id ? (
          <CategorySelect
            category_id={category_id}
            teachers_discipline_id={teachers_discipline_id}
            subcategory_id={subcategory_id}
          />
        ) : (
          <Wrapper label={t('edu.order.fields.category_id.label')}>
            <Select disabled style={selectStyle} />
          </Wrapper>
        )}
      </Col>
      <Col span={8} xs={24} md={8}>
        {category_id && teachers_discipline_id ? (
          <SubCategorySelect
            category_id={category_id}
            subcategory_id={subcategory_id}
            teachers_discipline_id={teachers_discipline_id}
          />
        ) : (
          <Wrapper label={t('edu.order.fields.sub_category_id.label')}>
            <Select disabled style={selectStyle} />
          </Wrapper>
        )}
      </Col>
      <TeacherDisciplineForm
        open={teacherDisciplineFormOpen}
        setOpen={setTeacherDisciplineFormOpen}
      />
    </Row>
  )
}
