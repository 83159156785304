import { authApi as api } from './index'
export const addTagTypes = ['/auth'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/auth/auth/login`,
          method: 'POST',
          body: queryArg.bodyLoginAuthAuthLoginPost,
        }),
        invalidatesTags: ['/auth'],
      }),
      refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>(
        {
          query: (queryArg) => ({
            url: `/auth/auth/refresh-token`,
            method: 'POST',
            body: queryArg.refreshTokenRequest,
          }),
          invalidatesTags: ['/auth'],
        }
      ),
      signUp: build.mutation<SignUpApiResponse, SignUpApiArg>({
        query: (queryArg) => ({
          url: `/auth/auth/sign-up`,
          method: 'POST',
          body: queryArg.signUpUserBody,
        }),
        invalidatesTags: ['/auth'],
      }),
      activate: build.query<ActivateApiResponse, ActivateApiArg>({
        query: (queryArg) => ({
          url: `/auth/auth/activate`,
          params: { token: queryArg.token },
        }),
        providesTags: ['/auth'],
      }),
      forgotPassword: build.mutation<
        ForgotPasswordApiResponse,
        ForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/auth/forgot-password`,
          method: 'POST',
          body: queryArg.forgotPasswordRequest,
        }),
        invalidatesTags: ['/auth'],
      }),
      resetPassword: build.mutation<
        ResetPasswordApiResponse,
        ResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/auth/reset-password`,
          method: 'POST',
          body: queryArg.resetPasswordRequest,
        }),
        invalidatesTags: ['/auth'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as authApi }
export type LoginApiResponse =
  /** status 200 Successful Response */ LoginResponse
export type LoginApiArg = {
  bodyLoginAuthAuthLoginPost: BodyLoginAuthAuthLoginPost
}
export type RefreshTokenApiResponse =
  /** status 200 Successful Response */ LoginResponse
export type RefreshTokenApiArg = {
  refreshTokenRequest: RefreshTokenRequest
}
export type SignUpApiResponse = /** status 201 Successful Response */ any
export type SignUpApiArg = {
  signUpUserBody: SignUpUserBody
}
export type ActivateApiResponse =
  /** status 201 Successful Response */ UserResponse
export type ActivateApiArg = {
  token?: string
}
export type ForgotPasswordApiResponse =
  /** status 200 Successful Response */ MessageResponse
export type ForgotPasswordApiArg = {
  forgotPasswordRequest: ForgotPasswordRequest
}
export type ResetPasswordApiResponse =
  /** status 200 Successful Response */ MessageResponse
export type ResetPasswordApiArg = {
  resetPasswordRequest: ResetPasswordRequest
}
export type TenantResponse = {
  name: string
  is_active: boolean
}
export type RegionType =
  | '\u0410\u043B\u043C\u0430\u0442\u044B'
  | '\u0410\u0441\u0442\u0430\u043D\u0430'
  | '\u0428\u044B\u043C\u043A\u0435\u043D\u0442'
  | '\u0410\u049B\u043C\u043E\u043B\u0430 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0410\u049B\u0442\u04E9\u0431\u0435 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0410\u043B\u043C\u0430\u0442\u044B \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0410\u0442\u044B\u0440\u0430\u0443 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0428\u044B\u0493\u044B\u0441 \u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u049A\u0430\u0440\u0430\u0493\u0430\u043D\u0434\u044B \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u049A\u043E\u0441\u0442\u0430\u043D\u0430\u0439 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u049A\u044B\u0437\u044B\u043B\u043E\u0440\u0434\u0430 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u041C\u0430\u04A3\u0493\u044B\u0441\u0442\u0430\u0443 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0421\u043E\u043B\u0442\u04AF\u0441\u0442\u0456\u043A \u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u041F\u0430\u0432\u043B\u043E\u0434\u0430\u0440 \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0422\u04AF\u0440\u043A\u0456\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0411\u0430\u0442\u044B\u0441 \u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D \u043E\u0431\u043B\u044B\u0441\u044B'
  | '\u0416\u0430\u043C\u0431\u044B\u043B \u043E\u0431\u043B\u044B\u0441\u044B'
export type SexEnum = 'male' | 'female'
export type FeatureName =
  | 'order_submission'
  | 'expanding_result'
  | 'document_generation'
export type PlanFeatureResponse = {
  id: string
  feature_name: FeatureName
  feature_limit?: number | null
  is_unlimited: boolean
  is_enabled: boolean
  description?: string | null
}
export type PlanResponse = {
  id: string
  name: string
  description: string | null
  price: number | null
  billing_cycle_days: number | null
  is_active: boolean
  is_trial_available: boolean
  trial_days: number | null
  created_by: string | null
  created_at: string
  features?: PlanFeatureResponse[]
}
export type SubscriptionStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'CANCELED'
  | 'EXPIRED'
  | 'PENDING'
export type SubscriptionResponse = {
  id: number
  user_id: string
  plan: PlanResponse
  phone_number: string | null
  status: SubscriptionStatus
  valid_until: string
  start_date: string
}
export type RoleResponse = {
  id: number
  name: string
}
export type UserResponse = {
  id: string
  email: string
  first_name?: string | null
  last_name?: string | null
  is_admin: boolean
  is_activated: boolean
  /** The tenant associated with the user. */
  tenant?: TenantResponse | null
  region?: RegionType | null
  date_of_birth?: string | null
  sex?: SexEnum | null
  school?: string | null
  assistant_id?: string | null
  subscription?: SubscriptionResponse
  /** List of roles associated with the user */
  roles: RoleResponse[]
  password?: string | null
}
export type LoginResponse = {
  token_type?: string
  access_token: string
  expires_at: number
  refresh_token: string
  refresh_token_expires_at: number
  user: UserResponse
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type BodyLoginAuthAuthLoginPost = {
  grant_type?: string | null
  username: string
  password: string
  scope?: string
  client_id?: string | null
  client_secret?: string | null
}
export type RefreshTokenRequest = {
  refresh_token: string
}
export type SignUpUserBody = {
  email: string
  password: string
  first_name?: string
  last_name?: string
  region?: RegionType | null
  date_of_birth?: string | null
  sex?: SexEnum | null
  school?: string | null
  tenant?: string | null
}
export type MessageResponse = {
  message: string
}
export type ForgotPasswordRequest = {
  email: string
}
export type ResetPasswordRequest = {
  token: string
  new_password: string
  confirm_password: string
}
export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useSignUpMutation,
  useActivateQuery,
  useLazyActivateQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = injectedRtkApi
