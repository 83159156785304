import {
  CaretRightOutlined,
  FileWordOutlined,
  LikeOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  StepForwardOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  FloatButton,
  Pagination,
  Radio,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import type { CheckboxOptionType } from 'antd/lib'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Debug from '../../../components/helpers/Debug'
import Iconify from '../../../components/Iconify'
import { formatTaskTypePath } from '../../../layouts/Main/components/TaskTypeSelect'
import type { UserResponse } from '../../../redux/api/auth/auth'
import { orderRunApiEnhanced } from '../../../redux/api/edu/enhanced'
import type {
  OrderResponse,
  UsageStatisticsResponse,
} from '../../../redux/api/edu/order'
import {
  orderApi,
  useOrderGetDailyUsageStatisticsQuery,
  useOrderSubmitMutation,
} from '../../../redux/api/edu/order'
import {
  useOrderRunExpandResultMutation,
  useOrderRunLikeMutation,
  useOrderRunRenderResultToFileMutation,
  type OrderRunResponse,
} from '../../../redux/api/edu/order_run'
import type { FeatureName } from '../../../redux/api/edu/plan_feature'
import { mapStatusT } from '../../../redux/slices/edu/order'
import { setIsPromoOpen } from '../../../redux/slices/mainSlice'

import RunBody from './RunBody'

const { Title } = Typography
const prettyPrintInstruction = (instruction: string): JSX.Element[] => {
  return instruction.split('\n').map((line, index) => {
    const isBullet = line.trim().startsWith('-') || line.trim().startsWith('*')
    return (
      <React.Fragment key={index}>
        <span style={{ display: 'block', marginLeft: isBullet ? '1rem' : '0' }}>
          {line.trim()}
        </span>
        <br />
      </React.Fragment>
    )
  })
}

export type TResultType = 'completion_result' | 'expanded_result'

const parseStatistics = (
  statistics: UsageStatisticsResponse | undefined,
  key: FeatureName,
) => {
  return statistics
    ? Object.entries(statistics.usage).find(
        ([k, v]) => (k as FeatureName) === key,
      )?.[1]
    : undefined
}

type Decision = {
  limitExceeded: boolean
  finishPrimary: boolean
  isAvailable: boolean
  upgradable: boolean
}

export default function PlaygroundBody({
  order,
  user,
  orderRuns,
}: {
  order: OrderResponse
  user: UserResponse
  orderRuns?: OrderRunResponse[]
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [resultType, setResultType] = useState<TResultType>('completion_result')

  const { data: statistics } = useOrderGetDailyUsageStatisticsQuery()
  const [orderSubmit, { isLoading: isSubmitLoading }] = useOrderSubmitMutation()
  const [orderRender, { isLoading: isRenderLoading }] =
    useOrderRunRenderResultToFileMutation()
  const [orderRunLike] = useOrderRunLikeMutation()

  const [orderExpand, { isLoading: isExpandLoading }] =
    useOrderRunExpandResultMutation()

  const isLoading = isSubmitLoading || isRenderLoading || isExpandLoading

  const isEditDisabled = !(
    order.status === 'Created' || order.status === 'Failed'
  )
  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    // background: token.colorFillAlter,
    borderRadius: 0,
    border: 'none',
    margin: 0,
    padding: 0,
  }
  const isPrimaryCompleted =
    order.status === 'Created' || order.status === 'Failed'

  const useFeatureProvider = (featureName: FeatureName, tBase: string) => {
    const feature = user.subscription?.plan?.features?.find(
      ({ feature_name }) => feature_name === featureName,
    )

    const usageStatistics = parseStatistics(statistics, featureName)

    const decision: Decision = {
      isAvailable: feature ? feature.is_enabled : false,
      finishPrimary: isPrimaryCompleted,
      limitExceeded:
        usageStatistics !== undefined ? usageStatistics.remaining <= 0 : false,
      upgradable: usageStatistics?.upgradable || false,
    }

    const tooltip = decision.finishPrimary
      ? t(`edu.order.form.actions.${tBase}.finishGeneration`)
      : decision.limitExceeded
        ? t(`edu.order.form.actions.${tBase}.limitExceeded`, {
            limit: statistics?.usage,
          })
        : t(`edu.order.form.actions.${tBase}.title`)
    return {
      feature,
      usageStatistics,
      decision,
      tooltip,
    }
  }

  // document generation
  const {
    decision: docDecision,
    feature: documentGenerationFeature,
    tooltip: DOCXTooltip,
  } = useFeatureProvider('document_generation', 'generateDocx')

  // expanding result
  const {
    decision: expandingResultDecision,
    feature: expandingResultFeature,
    tooltip: expansionTooltip,
  } = useFeatureProvider('expanding_result', 'expand')

  // order submission
  const {
    decision: regenerateDecision,
    feature: regenerateFeature,
    tooltip: RegenerateTooltip,
  } = useFeatureProvider('order_submission', 'regenerate')

  const isLimitExceeded = false

  const [page, setPage] = useState<number>(1)

  const orderRun = orderRuns ? orderRuns[page - 1] : undefined

  const isDOCXDisabled = isPrimaryCompleted || isLimitExceeded
  const isRegenerateDisabled = isPrimaryCompleted || isLimitExceeded

  const isExpansionDisabled = isPrimaryCompleted || isLimitExceeded

  const approveTooltip = isLimitExceeded
    ? t('edu.order.form.actions.approve.limitExceeded', {
        limit: statistics?.usage,
      })
    : orderRun !== undefined
      ? t('edu.order.form.actions.approve.alreadyRun')
      : t('edu.order.form.actions.approve.title')

  const isApproveDisabled = isLimitExceeded || orderRun !== undefined

  const options: CheckboxOptionType<TResultType>[] = [
    {
      label: t('edu.orderRun.fields.result_type.options.completion_result'),
      value: 'completion_result',
    },
    {
      label: t('edu.orderRun.fields.result_type.options.expanded_result'),
      value: 'expanded_result',
      disabled: !orderRun?.expanded_result,
    },
  ]

  return (
    <>
      <Debug
        debug
        objects={{ docDecision, expandingResultDecision, regenerateDecision }}
      />
      <Debug objects={{ user }} debug />
      <Debug objects={{ resultType }} debug />
      <Debug
        debug
        objects={{
          documentGenerationFeature,
          expandingResultFeature,
          regenerateFeature,
        }}
      />
      <Debug debug objects={{ order }} />
      <Debug debug objects={{ orderRuns }} />
      <Debug debug objects={{ statistics }} />
      <Row>
        <Col xs={16}>
          <Title
            level={2}
            style={{
              color: 'var(--color-primary-orange)',
            }}
          >
            {order.title}
          </Title>
        </Col>
        <Col
          xs={8}
          style={{
            alignItems: 'center',
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            flexWrap: 'nowrap',
          }}
        >
          <Tag color="volcano">
            {`${t(`edu.order.fields.response_language.label`)}: ${t(
              `edu.order.fields.response_language.options.${order.response_language}`,
            )}`}
          </Tag>
          <Tag color="cyan">
            {`${t('edu.order.fields.task_type.label')}: ${t(formatTaskTypePath(order.task_type))}`}
          </Tag>
          <Tag color="blue">
            {`${t('edu.order.fields.status.label')}: ${mapStatusT(order.status, t)}`}
          </Tag>
        </Col>
      </Row>

      <Card
        style={{
          // width: '100%',
          backgroundColor: 'var(--color-light-gray)',
          borderRadius: 15,
          marginBottom: 20,
        }}
      >
        <Row>
          <Title
            style={{ color: 'var(--violet)', padding: 0, margin: 0 }}
            level={3}
          >
            {t('edu.order.fields.instruction.label')}
          </Title>
        </Row>
        <Row>
          <Typography style={{ color: 'var(--violet)' }}>
            {prettyPrintInstruction(order.instruction)}
          </Typography>
        </Row>
      </Card>

      <Title level={4}>{t('edu.order.fields.input_text.label')}</Title>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{ background: token.colorBgContainer }}
        items={[
          {
            key: '1',
            label: (
              <div
                style={{
                  display: 'grid',
                }}
              >
                <div
                  style={{
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2, // 2 lines when collapsed
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                  }}
                >
                  {order.input_text}
                </div>
              </div>
            ),
            children: (
              <p>
                {order.input_text && prettyPrintInstruction(order.input_text)}
              </p>
            ),
            style: panelStyle,
          },
        ]}
      />

      <Divider />

      {orderRun ? (
        <>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <div style={{ display: 'flex', gap: 15 }}>
              <Title
                style={{
                  color: 'var(--color-primary-orange)',
                  padding: 0,
                  margin: 0,
                }}
                level={3}
              >
                {t('edu.order.fields.result_text.label')}
              </Title>
              <Radio.Group
                options={options}
                onChange={(v) => setResultType(v.target.value)}
                value={resultType}
                optionType="button"
                buttonStyle="solid"
              />
            </div>

            {orderRuns && orderRuns.length >= 2 && (
              <Pagination
                total={orderRuns.length}
                pageSize={1}
                current={page}
                onChange={(page) => {
                  setPage(page)
                  if (!orderRuns[page - 1].expanded_result) {
                    setResultType('completion_result')
                  }
                }}
              />
            )}
          </div>
          <RunBody orderRun={orderRun} resultType={resultType} />
        </>
      ) : (
        <Title
          style={{
            color: 'var(--color-primary-orange)',
            padding: 0,
            margin: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          level={5}
        >
          <QuestionCircleOutlined style={{ fontSize: 24 }} />
          &nbsp; &nbsp; &nbsp;
          {t('edu.order.form.actions.approve.hint')}
        </Title>
      )}

      <div
        style={{
          marginTop: '15px',
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <FloatButton.Group
          open={true}
          // trigger="click"
          style={{ insetInlineEnd: 24 }}
          // icon={<CustomerServiceOutlined />}
        >
          {orderRun && (
            <>
              <Button
                // disabled
                // loading={isLoading}
                shape="circle"
                size="large"
                type={orderRun.liked ? 'primary' : 'default'}
                // style={{
                //   background: 'green',
                // }}
                icon={<LikeOutlined />}
                onClick={async () => {
                  if (orderRun && !orderRun.liked)
                    await orderRunLike({ orderRunId: orderRun._id })
                }}
              />

              <Tooltip placement="topLeft" arrow title={RegenerateTooltip}>
                <Button
                  disabled={isRegenerateDisabled}
                  loading={isLoading}
                  shape="circle"
                  size="large"
                  type="default"
                  icon={<ReloadOutlined />}
                  onClick={async () => {
                    if (
                      regenerateDecision.isAvailable &&
                      !regenerateDecision.limitExceeded
                    ) {
                      await orderSubmit({ orderId: order._id }).then(() => {
                        dispatch(
                          orderRunApiEnhanced.util.invalidateTags([
                            'order_run',
                          ]),
                        )
                        setResultType('completion_result')
                      })
                    } else if (!regenerateDecision.isAvailable) {
                      dispatch(setIsPromoOpen(true))
                    } else {
                      if (regenerateDecision.upgradable) {
                        dispatch(setIsPromoOpen(true))
                      }
                      toast('Limit exceeded')
                    }
                  }}
                />
              </Tooltip>
              {/* EXPANSION */}
              <Tooltip placement="topLeft" arrow title={expansionTooltip}>
                <Button
                  type="default"
                  loading={isLoading}
                  disabled={isExpansionDisabled}
                  shape="circle"
                  size="large"
                  icon={
                    <Iconify icon="carbon:ai-generate" width="20" height="20" />
                  }
                  onClick={async () => {
                    if (
                      expandingResultDecision.isAvailable &&
                      !expandingResultDecision.limitExceeded
                    ) {
                      await orderExpand({ orderRunId: orderRun._id }).then(
                        () => {
                          setResultType('expanded_result')
                          dispatch(orderApi.util.invalidateTags(['order']))
                        },
                      )
                    } else if (!expandingResultDecision.isAvailable) {
                      dispatch(setIsPromoOpen(true))
                    } else {
                      if (expandingResultDecision.upgradable) {
                        dispatch(setIsPromoOpen(true))
                      }
                      toast('Limit exceeded')
                    }
                  }}
                />
              </Tooltip>

              {/* DOCX */}
              <Tooltip placement="topLeft" arrow title={DOCXTooltip}>
                <Button
                  type="default"
                  loading={isLoading}
                  disabled={isDOCXDisabled}
                  icon={<FileWordOutlined />}
                  shape="circle"
                  size="large"
                  onClick={() => {
                    if (docDecision.isAvailable && !docDecision.limitExceeded) {
                      orderRender({
                        orderRunId: orderRun._id,
                        resultType,
                      })
                    } else if (!docDecision.isAvailable) {
                      dispatch(setIsPromoOpen(true))
                    } else {
                      if (docDecision.upgradable) {
                        dispatch(setIsPromoOpen(true))
                      }
                      toast('Limit exceeded')
                    }
                  }}
                />
              </Tooltip>
            </>
          )}
          <Tooltip placement="topLeft" arrow title={approveTooltip}>
            <Button
              type="primary"
              loading={isLoading}
              className={isApproveDisabled ? undefined : 'pulsating-button'}
              style={{
                background: 'green',
              }}
              disabled={isApproveDisabled}
              icon={<StepForwardOutlined />}
              size="large"
              shape="circle"
              onClick={async () => {
                if (!isLimitExceeded) {
                  await orderSubmit({ orderId: order._id }).then(() => {
                    dispatch(
                      orderRunApiEnhanced.util.invalidateTags(['order_run']),
                    )
                  })
                }
              }}
            />
          </Tooltip>
        </FloatButton.Group>

        <div
          style={{
            gap: 10,
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Debug objects={{ isEditDisabled }} />
        </div>
      </div>
    </>
  )
}
