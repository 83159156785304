import { eduApi as api } from './index'
export const addTagTypes = ['teachers_discipline'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      teacherDisciplinesList: build.query<
        TeacherDisciplinesListApiResponse,
        TeacherDisciplinesListApiArg
      >({
        query: () => ({ url: `/edu/teachers_discipline/` }),
        providesTags: ['teachers_discipline'],
      }),
      teacherDisciplineCreate: build.mutation<
        TeacherDisciplineCreateApiResponse,
        TeacherDisciplineCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/teachers_discipline/`,
          method: 'POST',
          body: queryArg.teachersDisciplineBody,
        }),
        invalidatesTags: ['teachers_discipline'],
      }),
      teacherDisciplineRetrieve: build.query<
        TeacherDisciplineRetrieveApiResponse,
        TeacherDisciplineRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/teachers_discipline/${queryArg.id}`,
        }),
        providesTags: ['teachers_discipline'],
      }),
      teacherDisciplineUpdate: build.mutation<
        TeacherDisciplineUpdateApiResponse,
        TeacherDisciplineUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/teachers_discipline/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.teachersDisciplineBody,
        }),
        invalidatesTags: ['teachers_discipline'],
      }),
      teacherDisciplineDelete: build.mutation<
        TeacherDisciplineDeleteApiResponse,
        TeacherDisciplineDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/teachers_discipline/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['teachers_discipline'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as teachers_disciplineApi }
export type TeacherDisciplinesListApiResponse =
  /** status 200 Successful Response */ TeachersDisciplineResponse[]
export type TeacherDisciplinesListApiArg = void
export type TeacherDisciplineCreateApiResponse =
  /** status 201 Successful Response */ TeachersDisciplineResponse
export type TeacherDisciplineCreateApiArg = {
  teachersDisciplineBody: TeachersDisciplineBody
}
export type TeacherDisciplineRetrieveApiResponse =
  /** status 200 Successful Response */ TeachersDisciplineResponse
export type TeacherDisciplineRetrieveApiArg = {
  id: string
}
export type TeacherDisciplineUpdateApiResponse =
  /** status 200 Successful Response */ TeachersDisciplineResponse
export type TeacherDisciplineUpdateApiArg = {
  id: string
  teachersDisciplineBody: TeachersDisciplineBody
}
export type TeacherDisciplineDeleteApiResponse =
  /** status 204 Successful Response */ void
export type TeacherDisciplineDeleteApiArg = {
  id: string
}
export type DisciplineResponse = {
  id: string
  title: string
  kz: string
  ru: string
  en: string
}
export type SubcategoryResponse = {
  /** The name of the subcategory. */
  title: string
  /** The UUID of the related Discipline. */
  teachers_discipline_id: string
  /** The UUID of the related Category. */
  category_id: string
  /** The unique identifier of the subcategory. */
  id: string
}
export type CategoryResponse = {
  /** The name of the category. */
  title: string
  /** The UUID of the related TeachersDiscipline. */
  teachers_discipline_id: string
  /** The unique identifier of the category. */
  id: string
  /** A list of associated subcategories. */
  subcategories?: SubcategoryResponse[] | null
}
export type TeachersDisciplineResponse = {
  /** UUID of the TeachersDiscipline entry. */
  id: string
  /** UUID of the associated User. */
  user_id: string
  discipline: DisciplineResponse
  /** A list of associated subcategories. */
  categories?: CategoryResponse[] | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type TeachersDisciplineBody = {
  /** Optional UUID of the associated Discipline. If provided, ensures discipline_name is synchronized. */
  discipline_id?: string | null
}
export const {
  useTeacherDisciplinesListQuery,
  useLazyTeacherDisciplinesListQuery,
  useTeacherDisciplineCreateMutation,
  useTeacherDisciplineRetrieveQuery,
  useLazyTeacherDisciplineRetrieveQuery,
  useTeacherDisciplineUpdateMutation,
  useTeacherDisciplineDeleteMutation,
} = injectedRtkApi
