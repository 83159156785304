import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import { EduPlaygroundRoute } from '../../routes/eduRoutes'

import BodyWrapper from './components/BodyWrapper'

export default function EduPlayground() {
  const { t } = useTranslation()
  const { teachers_discipline_id, category_id, subcategory_id, order_id } =
    useParams()
  const { user } = useAppSelector((state) => state.auth)

  return (
    <Page title={t(EduPlaygroundRoute.title)}>
      <Debug
        objects={{
          teachers_discipline_id,
          category_id,
          subcategory_id,
          order_id,
        }}
      />

      {order_id && user && <BodyWrapper order_id={order_id} user={user} />}
    </Page>
  )
}
