import { Col, Form, Modal, Row } from 'antd'
import { useEffect, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { IFormBody } from '../../../components/Form/helpers'
import FormSelect from '../../../components/Form/Select'
import Debug from '../../../components/helpers/Debug'
import { languageState } from '../../../i18n'
import { useDisciplineListQuery } from '../../../redux/api/edu/discipline'
import {
  useTeacherDisciplineCreateMutation,
  type TeachersDisciplineBody,
} from '../../../redux/api/edu/teachers_discipline'
import { getTeacherDisciplineModelDescriptor } from '../../../redux/slices/edu/teacherDiscipline'
import { getOnSelect } from '../../../utils/onSelect'

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function TeacherDisciplineForm({ open, setOpen }: Props) {
  type FormBody = IFormBody<TeachersDisciplineBody, never>
  const [form] = Form.useForm<FormBody>()
  const { t } = useTranslation()
  const fmd = getTeacherDisciplineModelDescriptor(t)

  const [teacherDisciplineCreate, { error }] =
    useTeacherDisciplineCreateMutation()
  useEffect(() => {
    if (error) fmd.handleErrors(form as any, error)
  }, [error])
  const navigate = useNavigate()
  const { teachers_discipline_id, category_id, subcategory_id, order_id } =
    useParams()

  const onSelect = getOnSelect(
    navigate,
    teachers_discipline_id,
    category_id,
    subcategory_id,
  )

  const handleOk = async () => {
    const { discipline_id } = form.getFieldsValue()
    await teacherDisciplineCreate({
      teachersDisciplineBody: {
        discipline_id,
      },
    })
      .unwrap()
      .then(({ id }) => {
        fmd.toast.created()
        onSelect('discipline')(id)
      })
    setOpen(false)
  }
  const { data: disciplines } = useDisciplineListQuery({})
  const handleClose = () => {
    setOpen(false)
    form.resetFields()
  }

  const values = Form.useWatch([], form)
  const initialValues = {}
  return (
    <Modal
      title={fmd.form.action.create()}
      open={open}
      width={'800px'}
      onOk={handleOk}
      onCancel={handleClose}
      afterOpenChange={() => form.resetFields()}
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        size="small"
        initialValues={initialValues}
      >
        <Debug objects={{ initialValues }} />
        <Debug objects={{ values }} />
        <Row gutter={30}>
          <Col span={24}>
            <FormSelect
              formField={fmd.fields.discipline_id}
              options={
                disciplines
                  ? disciplines.map(({ title, en, id, kz, ru }) => ({
                      key: id,
                      value: id,
                      label:
                        languageState === 'kk-KZ'
                          ? kz
                          : languageState === 'ru-RU'
                            ? ru
                            : en,
                    }))
                  : []
              }
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
