import assistant from './kk-KZ/assistant.json'
import assistant_file from './kk-KZ/assistant_file.json'
import business from './kk-KZ/business.json'
import business_file from './kk-KZ/business_file.json'
import category from './kk-KZ/category.json'
import feedback from './kk-KZ/feedback.json'
import footer from './kk-KZ/footer.json'
import form from './kk-KZ/form.json'
import language_selector from './kk-KZ/language_selector.json'
import layouts from './kk-KZ/layouts.json'
import navbar from './kk-KZ/navbar.json'
import order from './kk-KZ/order'
import orderRun from './kk-KZ/orderRun.json'
import pages from './kk-KZ/pages.json'
import planFeature from './kk-KZ/plan_feature.json'
import select from './kk-KZ/select.json'
import subCategory from './kk-KZ/subCategory.json'
import subscription from './kk-KZ/subscription.json'
import teacherDiscipline from './kk-KZ/teacherDiscipline.json'
import thread from './kk-KZ/thread'
import user from './kk-KZ/user.json'
import vllm_params from './kk-KZ/vllm_params.json'
export default {
  trademark: 'ProntoEdu',
  business: {
    business,
    business_file,
  },
  edu: {
    teacherDiscipline,
    order,
    category,
    subCategory,
    orderRun,
    subscription,
    planFeature,
  },
  assistant: {
    assistant,
    assistant_file,
    thread,
    feedback,
    vllm_params,
  },
  auth: {
    user,
  },
  components: {
    footer,
    form,
    language_selector,
    navbar,
    select,
  },
  layouts,
  pages,
}
