import { eduApi as api } from './index'
export const addTagTypes = ['plan'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      planCreate: build.mutation<PlanCreateApiResponse, PlanCreateApiArg>({
        query: (queryArg) => ({
          url: `/edu/plan/`,
          method: 'POST',
          body: queryArg.planCreate,
        }),
        invalidatesTags: ['plan'],
      }),
      planList: build.query<PlanListApiResponse, PlanListApiArg>({
        query: (queryArg) => ({
          url: `/edu/plan/`,
          params: { name: queryArg.name },
        }),
        providesTags: ['plan'],
      }),
      planRetrieve: build.query<PlanRetrieveApiResponse, PlanRetrieveApiArg>({
        query: (queryArg) => ({ url: `/edu/plan/${queryArg.id}` }),
        providesTags: ['plan'],
      }),
      planUpdate: build.mutation<PlanUpdateApiResponse, PlanUpdateApiArg>({
        query: (queryArg) => ({
          url: `/edu/plan/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.planUpdate,
        }),
        invalidatesTags: ['plan'],
      }),
      planDelete: build.mutation<PlanDeleteApiResponse, PlanDeleteApiArg>({
        query: (queryArg) => ({
          url: `/edu/plan/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['plan'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as planApi }
export type PlanCreateApiResponse =
  /** status 201 Successful Response */ PlanResponse
export type PlanCreateApiArg = {
  planCreate: PlanCreate
}
export type PlanListApiResponse =
  /** status 200 Successful Response */ PlanResponse[]
export type PlanListApiArg = {
  /** Filter plans by name */
  name?: string | null
}
export type PlanRetrieveApiResponse =
  /** status 200 Successful Response */ PlanResponse
export type PlanRetrieveApiArg = {
  id: string
}
export type PlanUpdateApiResponse =
  /** status 200 Successful Response */ PlanResponse
export type PlanUpdateApiArg = {
  id: string
  planUpdate: PlanUpdate
}
export type PlanDeleteApiResponse = /** status 204 Successful Response */ void
export type PlanDeleteApiArg = {
  id: string
}
export type FeatureName =
  | 'order_submission'
  | 'expanding_result'
  | 'document_generation'
export type PlanFeatureResponse = {
  id: string
  feature_name: FeatureName
  feature_limit?: number | null
  is_unlimited: boolean
  is_enabled: boolean
  description?: string | null
}
export type PlanResponse = {
  id: string
  name: string
  description: string | null
  price: number | null
  billing_cycle_days: number | null
  is_active: boolean
  is_trial_available: boolean
  trial_days: number | null
  created_by: string | null
  created_at: string
  features?: PlanFeatureResponse[]
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type PlanCreate = {
  name: string
  description?: string | null
  price?: number | null
  billing_cycle_days?: number | null
  is_active?: boolean
  is_trial_available?: boolean
  trial_days?: number | null
  created_by?: string | null
}
export type PlanUpdate = {
  name?: string | null
  description?: string | null
  price?: number | null
  billing_cycle_days?: number | null
  is_active?: boolean | null
  is_trial_available?: boolean | null
  trial_days?: number | null
  created_by?: string | null
}
export const {
  usePlanCreateMutation,
  usePlanListQuery,
  useLazyPlanListQuery,
  usePlanRetrieveQuery,
  useLazyPlanRetrieveQuery,
  usePlanUpdateMutation,
  usePlanDeleteMutation,
} = injectedRtkApi
