import { eduApi as api } from './index'
export const addTagTypes = ['category'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      categoryCreate: build.mutation<
        CategoryCreateApiResponse,
        CategoryCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/category/`,
          method: 'POST',
          body: queryArg.categoryBody,
        }),
        invalidatesTags: ['category'],
      }),
      categoryList: build.query<CategoryListApiResponse, CategoryListApiArg>({
        query: (queryArg) => ({
          url: `/edu/category/`,
          params: { teachers_discipline_id: queryArg.teachersDisciplineId },
        }),
        providesTags: ['category'],
      }),
      categoryRetrieve: build.query<
        CategoryRetrieveApiResponse,
        CategoryRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/edu/category/${queryArg.id}` }),
        providesTags: ['category'],
      }),
      categoryUpdate: build.mutation<
        CategoryUpdateApiResponse,
        CategoryUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/category/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.categoryBody,
        }),
        invalidatesTags: ['category'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as categoryApi }
export type CategoryCreateApiResponse =
  /** status 201 Successful Response */ CategoryResponse
export type CategoryCreateApiArg = {
  categoryBody: CategoryBody
}
export type CategoryListApiResponse =
  /** status 200 Successful Response */ CategoryResponse[]
export type CategoryListApiArg = {
  /** Filter categories by teachers_discipline_id */
  teachersDisciplineId: string
}
export type CategoryRetrieveApiResponse =
  /** status 200 Successful Response */ CategoryResponse
export type CategoryRetrieveApiArg = {
  id: string
}
export type CategoryUpdateApiResponse =
  /** status 200 Successful Response */ CategoryResponse
export type CategoryUpdateApiArg = {
  id: string
  categoryBody: CategoryBody
}
export type SubcategoryResponse = {
  /** The name of the subcategory. */
  title: string
  /** The UUID of the related Discipline. */
  teachers_discipline_id: string
  /** The UUID of the related Category. */
  category_id: string
  /** The unique identifier of the subcategory. */
  id: string
}
export type CategoryResponse = {
  /** The name of the category. */
  title: string
  /** The UUID of the related TeachersDiscipline. */
  teachers_discipline_id: string
  /** The unique identifier of the category. */
  id: string
  /** A list of associated subcategories. */
  subcategories?: SubcategoryResponse[] | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CategoryBody = {
  /** The name of the category. */
  title: string
  /** The UUID of the related TeachersDiscipline. */
  teachers_discipline_id: string
}
export const {
  useCategoryCreateMutation,
  useCategoryListQuery,
  useLazyCategoryListQuery,
  useCategoryRetrieveQuery,
  useLazyCategoryRetrieveQuery,
  useCategoryUpdateMutation,
} = injectedRtkApi
