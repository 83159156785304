import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import type { RootState } from '../app/store'

export const baseUrl = import.meta.env.VITE_BASE_URL

// Create the raw baseQuery
const rawBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
  paramsSerializer: (params: Record<string, unknown>) =>
    queryString.stringify(params, { arrayFormat: 'bracket' }),
})

// Wrap it so we can catch & log any errors
export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await rawBaseQuery(args, api, extraOptions)

  // Check for error and log it
  if (result.error) {
    if (result.error.status === 500) {
      toast.error(
        `Something went wrong! Erorr=${result.error.status} ${result.error.data}`,
      )
    } else if (result.error.status === 400) {
      toast.error(
        `Something went wrong! Erorr=${result.error.status} ${JSON.stringify(result.error.data)}`,
      )
    }
  }

  return result
}

export default baseQuery
