import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const sectionStyle = {
  // marginBottom: '20px',
  // borderBottom: '1px solid #eee',
  // paddingBottom: '15px',
}

export const TOC = ({
  isModalOpen,
  setIsModalOpen,
}: {
  setIsModalOpen: (a: boolean) => void
  isModalOpen: boolean
}) => {
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const { t } = useTranslation()

  const basePath = 'auth.user.signUpForm.toc.body'
  return (
    <>
      <Modal
        title={t('auth.user.signUpForm.toc.title')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'90%'}
        footer={null}
      >
        <div
          style={{
            fontFamily: 'Arial, sans-serif',
            lineHeight: '1.6',
            width: '100%',
            margin: '0 auto',
            padding: '20px',
          }}
        >
          <section style={sectionStyle}>
            <h2>{t(`${basePath}.acceptanceOfTerms.title`)}</h2>
            <p>{t(`${basePath}.acceptanceOfTerms.content`)}</p>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.platformDescription.title`)}</h2>
            <p>{t(`${basePath}.platformDescription.content`)}</p>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.userResponsibilities.title`)}</h2>
            <ul>
              <li>{t(`${basePath}.userResponsibilities.point1`)}</li>
              <li>{t(`${basePath}.userResponsibilities.point2`)}</li>
              <li>{t(`${basePath}.userResponsibilities.point3`)}</li>
            </ul>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.contentLimitations.title`)}</h2>
            <p>{t(`${basePath}.contentLimitations.intro`)}</p>
            <ul>
              <li>{t(`${basePath}.contentLimitations.point1`)}</li>
              <li>{t(`${basePath}.contentLimitations.point2`)}</li>
              <li>{t(`${basePath}.contentLimitations.point3`)}</li>
            </ul>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.intellectualProperty.title`)}</h2>
            <p>{t(`${basePath}.intellectualProperty.content`)}</p>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.disclaimerOfWarranties.title`)}</h2>
            <p>{t(`${basePath}.disclaimerOfWarranties.intro`)}</p>
            <ul>
              <li>{t(`${basePath}.disclaimerOfWarranties.point1`)}</li>
              <li>{t(`${basePath}.disclaimerOfWarranties.point2`)}</li>
              <li>{t(`${basePath}.disclaimerOfWarranties.point3`)}</li>
            </ul>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.limitationOfLiability.title`)}</h2>
            <p>{t(`${basePath}.limitationOfLiability.content`)}</p>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.userConduct.title`)}</h2>
            <p>{t(`${basePath}.userConduct.intro`)}</p>
            <ul>
              <li>{t(`${basePath}.userConduct.point1`)}</li>
              <li>{t(`${basePath}.userConduct.point2`)}</li>
              <li>{t(`${basePath}.userConduct.point3`)}</li>
            </ul>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.modificationsToTerms.title`)}</h2>
            <p>{t(`${basePath}.modificationsToTerms.content`)}</p>
          </section>

          <section style={sectionStyle}>
            <h2>{t(`${basePath}.contactInformation.title`)}</h2>
            <p>
              {t(`${basePath}.contactInformation.content`)}{' '}
              <a href="mailto:support@pronto-edu.com">
                {t(`${basePath}.contactInformation.email`)}
              </a>
              .
            </p>
          </section>

          <footer>
            <p>
              <strong>{t(`${basePath}.footer.lastUpdated`)}</strong>{' '}
              {t(`${basePath}.footer.date`)}
            </p>
          </footer>
        </div>
      </Modal>
    </>
  )
}
