import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Button, Form } from 'antd'

type Props = {}
const stripePromise = loadStripe('your-publishable-key')
export default function StripeForm({}: Props) {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      // type: 'card',
      // card: cardElement,
    } as any)

    if (error) {
      console.error(error)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      // Handle successful payment method creation
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <Form onFinish={handleSubmit}>
        <CardElement />
        <Button type="primary" htmlType="submit">
          Pay with Stripe
        </Button>
      </Form>
    </Elements>
  )
}
