import { Button, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import Debug from '../../../../components/helpers/Debug'
import { setIsLoginOpen } from '../../../../redux/slices/mainSlice'
import { paths } from '../../../../routes/paths'

import Fractal from './Fractal'

const { Title, Paragraph } = Typography

function HeroSection() {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth)

  const isAuthorized = user !== null
  const dispatch = useAppDispatch()

  return (
    <>
      <Fractal>
        <Debug objects={{ isAuthorized, user }} />
        <Title
          level={1}
          style={{
            fontSize: '3rem', // Approx. "text-4xl/md:text-6xl"
            fontWeight: 'bold',
            color: 'white',
            marginBottom: '1rem',
          }}
        >
          {t('pages.landing.hero.title')}
        </Title>
        <Paragraph
          style={{
            color: 'white',
            fontSize: '1.25rem', // Approx. "text-xl"
            marginBottom: '2rem', // Approx. "mb-8"
          }}
        >
          {t('pages.landing.hero.subTitle')}
        </Paragraph>
        <Link
          to={paths.eduPlayground}
          onClick={(e) => {
            if (!isAuthorized) {
              e.preventDefault()
              dispatch(setIsLoginOpen(true))
            }
          }}
        >
          <Button type="primary" size="large">
            {t('pages.landing.hero.action')}
          </Button>
        </Link>
      </Fractal>
    </>
  )
}

export default HeroSection
