import { orderApi } from './order'
import { order_runApi } from './order_run'

// thread
export const orderApiEnhanced = orderApi.enhanceEndpoints({
  endpoints: {
    orderRenderResultToFile: {
      query: (queryArg) => ({
        url: `/edu/order/${queryArg.orderId}/render-result`,
        method: 'POST',
        responseHandler: async (response) => {
          const downloadUrl = window.URL.createObjectURL(await response.blob())
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `output.docx`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        },
        cache: 'no-cache',
      }),
    },
  },
})
export const orderRunApiEnhanced = order_runApi.enhanceEndpoints({
  endpoints: {
    orderRunRenderResultToFile: {
      query: (queryArg) => ({
        url: `/edu/order_run/${queryArg.orderRunId}/render-result`,
        method: 'POST',
        params: { result_type: queryArg.resultType },
        responseHandler: async (response) => {
          const downloadUrl = window.URL.createObjectURL(await response.blob())
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute(
            'download',
            response.headers.get('File-Name') || 'document.docx',
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
        },
        cache: 'no-cache',
      }),
    },
  },
})
