import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../app/hooks'
import type { tRole } from '../redux/slices/mainSlice'
import { paths } from '../routes/paths'

const PrivateRoute = ({ roles }: { roles?: tRole[] }) => {
  const location = useLocation()
  const { accessToken, user } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  // If we have an accessToken but no user, we might be in the process of loading
  if (accessToken !== null && user === null) {
    // Show a loading placeholder (or just return null)
    // and let MeLoader do its job fetching the user.
    // Once user is fetched, user should not be undefined or null anymore.
    return <div>Loading...</div>
  }

  // If user is null, that means we definitely don't have a valid user
  if (user === null) {
    // Save the current location to state so we can return here after login
    return <Navigate to={paths.home} state={{ from: location }} />
  }
  console.log(roles)
  console.log(user.roles)
  console.log(
    roles && user.roles.some(({ name }) => roles.includes(name as tRole)),
  )

  if (roles && !user.roles.some(({ name }) => roles.includes(name as tRole))) {
    return <Navigate to={paths.home} />
  }

  // If user is defined, render the protected content
  return <Outlet />
}

export default PrivateRoute
