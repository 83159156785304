// ----------------------------------------------------------------------
import { CrownFilled } from '@ant-design/icons'
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  Typography,
  theme,
} from 'antd'
import type { MenuProps } from 'antd/lib'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../app/hooks'
import type { UserResponse } from '../redux/api/auth/auth'
import { removeUser } from '../redux/slices/auth/userSlice'
import { paths } from '../routes/paths'
import { gray, light } from '../theme'
import useHasPermission from '../utils/permissions'

// ----------------------------------------------------------------------
const { useToken } = theme
const { Text } = Typography

type Props = {
  items?: MenuProps['items']
  user: UserResponse
}

export default function AccountDropdown({ user, items = [] }: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isAdmin = useHasPermission('admin')

  const handleLogout = () => {
    dispatch(removeUser())
  }
  const items2: MenuProps['items'] = [
    ...items,
    {
      key: '2',
      label: (
        <a onClick={handleLogout}>{t('components.navbar.popover.logout')}</a>
      ),
    },
    ...(isAdmin
      ? [
          {
            key: paths.adminUsers,
            label: (
              <Link to={paths.adminUsers}>
                {t('components.navbar.popover.adminUsers')}
              </Link>
            ),
          },
        ]
      : []),
  ]

  const { token } = useToken()
  const width = 200
  const { isDarkMode } = useAppSelector((state) => state.main)
  return (
    <>
      <Dropdown
        dropdownRender={(menu) => (
          <div
            style={{
              borderRadius: token.borderRadiusLG,
              boxShadow: token.boxShadowSecondary,
              backgroundColor: isDarkMode ? gray : light,
              padding: 5,
            }}
          >
            <div
              style={{
                marginTop: 2.5,
                marginBottom: 2.5,
                paddingLeft: 2.5,
                paddingRight: 2.5,
                display: 'flex', // Use flexbox to control layout
                flexDirection: 'column', // Arrange children in a column
                maxWidth: `${width}px`,
              }}
            >
              <Text type="secondary" style={{ fontSize: 12 }}>
                {user?.email || t('components.navbar.popover.login')}
              </Text>
              <Divider style={{ margin: 5 }} />
            </div>
            {/* <span
              style={{
                display: 'flex',
                marginLeft: '5px',
                marginBottom: '5px',
                justifyContent: 'space-between',
              }}
            >
              {t('components.navbar.accountDropdown.theme')}
              <Switch
                checkedChildren={
                  <Iconify
                    icon={'material-symbols-light:dark-mode-outline-rounded'}
                  />
                }
                unCheckedChildren={
                  <Iconify
                    icon={'material-symbols-light:light-mode-outline-rounded'}
                  />
                }
                defaultChecked={isDarkMode}
                onChange={(isDarkMode, e) => {
                  e.preventDefault()
                  dispatch(setIsDarkMode({ isDarkMode }))
                }}
              />
            </span> */}
            {React.cloneElement(menu as React.ReactElement, {
              style: {
                boxShadow: 'none',
              },
            })}
            <Divider style={{ margin: 0 }} />
          </div>
        )}
        menu={{ items: items2 }}
      >
        <Button type="link" size="small">
          <Badge
            count={
              user?.subscription?.plan?.name === 'Pro' ? (
                <CrownFilled
                  style={{
                    color: 'orange',
                  }}
                />
              ) : undefined
            }
          >
            <Avatar
              style={{
                verticalAlign: 'middle',
                background: 'var(--color-primary-purple)',
              }}
              size="small"
              gap={4}
            >
              {user
                ? user.first_name
                  ? user.first_name[0]
                  : user.email[0]
                : 'U'}
            </Avatar>
          </Badge>
        </Button>
      </Dropdown>
    </>
  )
}
