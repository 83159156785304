import type { FormItemProps } from 'antd'
import type { SelectProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'

import type { RegionType } from '../../redux/api/auth/auth'
import type { FormField } from '../../utils/modelDescriptor/formFields'

import type { FormElementProps } from './helpers'
import FormSelect from './Select'

type Props = {
  formfield: FormField<any>
  itemProps?: FormItemProps
  selectProps?: SelectProps
} & FormElementProps

export default function RegionSelect({
  formfield,
  selectProps,
  itemProps,
  ...rest
}: Props) {
  const { t } = useTranslation()
  const regionMapping: {
    [k in RegionType]: string
  } = {
    Алматы: t('auth.user.fields.region.options.Алматы'),
    Астана: t('auth.user.fields.region.options.Астана'),
    Шымкент: t('auth.user.fields.region.options.Шымкент'),
    'Алматы облысы': t('auth.user.fields.region.options.Алматы облысы'),
    'Атырау облысы': t('auth.user.fields.region.options.Атырау облысы'),
    'Ақмола облысы': t('auth.user.fields.region.options.Ақмола облысы'),
    'Ақтөбе облысы': t('auth.user.fields.region.options.Ақтөбе облысы'),
    'Батыс Қазақстан облысы': t(
      'auth.user.fields.region.options.Батыс Қазақстан облысы',
    ),
    'Жамбыл облысы': t('auth.user.fields.region.options.Жамбыл облысы'),
    'Маңғыстау облысы': t('auth.user.fields.region.options.Маңғыстау облысы'),
    'Павлодар облысы': t('auth.user.fields.region.options.Павлодар облысы'),
    'Солтүстік Қазақстан облысы': t(
      'auth.user.fields.region.options.Солтүстік Қазақстан облысы',
    ),
    'Түркістан облысы': t('auth.user.fields.region.options.Түркістан облысы'),
    'Шығыс Қазақстан облысы': t(
      'auth.user.fields.region.options.Шығыс Қазақстан облысы',
    ),
    'Қарағанды облысы': t('auth.user.fields.region.options.Қарағанды облысы'),
    'Қостанай облысы': t('auth.user.fields.region.options.Қостанай облысы'),
    'Қызылорда облысы': t('auth.user.fields.region.options.Қызылорда облысы'),
  }
  return (
    <FormSelect
      formField={formfield}
      itemProps={{
        required: false,
        ...itemProps,
      }}
      options={Object.entries(regionMapping).map(([k, v]) => ({
        key: k,
        value: k,
        label: v,
      }))}
      {...selectProps}
      {...rest}
    />
  )
}
