import type {
  Action,
  Middleware,
  MiddlewareAPI,
  ThunkAction,
} from '@reduxjs/toolkit'
import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { userApiEnhanced } from '../redux/api/auth/enhanced'
import { businessApi } from '../redux/api/business'
import { orderApiEnhanced } from '../redux/api/edu/enhanced'
import { monitoringApi } from '../redux/api/monitoring'
import { rbApi } from '../redux/api/rb'
// slices
import auth from '../redux/slices/auth/userSlice'
import edu from '../redux/slices/edu'
import main from '../redux/slices/mainSlice'

const rootReducer = combineReducers({
  [userApiEnhanced.reducerPath]: userApiEnhanced.reducer,
  [businessApi.reducerPath]: businessApi.reducer,
  [rbApi.reducerPath]: rbApi.reducer,
  [orderApiEnhanced.reducerPath]: orderApiEnhanced.reducer,
  [monitoringApi.reducerPath]: monitoringApi.reducer,
  auth,
  main,
  edu,
})

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn(action)
      // toast.warn(
      //   'data' in action.error
      //     ? (action.error.data as { message: string }).message
      //     : action.error.message,
      // )
    }

    return next(action)
  }

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat([
        monitoringApi.middleware,
        businessApi.middleware,
        rbApi.middleware,
        orderApiEnhanced.middleware,
        userApiEnhanced.middleware,
        rtkQueryErrorLogger,
      ])
    },
    preloadedState,
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
