import type { PopconfirmProps } from 'antd'
import { Button, Popconfirm, Select, Switch, Tag } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { TableProps } from 'antd/lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import MyTable from '../../components/MyTable'
import Page from '../../components/Page'
import type { UserAdminResponse } from '../../redux/api/auth/user'
import {
  userApi,
  useUserActivateMutation,
  useUserDeleteMutation,
  useUserListQuery,
} from '../../redux/api/auth/user'
import { usePlanListQuery } from '../../redux/api/edu/plan'
import { useSubscriptionSetMutation } from '../../redux/api/edu/subscription'
import { getUserModelDescriptor } from '../../redux/slices/auth/userSlice'
import { AdminUsersRoute } from '../../routes/clientRoutes'

type Props = {}

export default function AdminUsers({}: Props) {
  const { data } = useUserListQuery()
  const { t } = useTranslation()
  const umd = getUserModelDescriptor(t)
  const [activate] = useUserActivateMutation()
  const [deleteUser] = useUserDeleteMutation()

  const cancel: PopconfirmProps['onCancel'] = (e) => {
    console.log(e)
  }
  const { data: plans } = usePlanListQuery({})

  const planOptions: DefaultOptionType[] =
    plans?.map(({ id, name }) => ({
      value: id,
      title: name,
      label: name,
    })) || []

  const [subscriptionSet] = useSubscriptionSetMutation()
  const dispatch = useAppDispatch()

  const columns: TableProps<UserAdminResponse>['columns'] = [
    {
      title: umd.fields.email.label,
      dataIndex: umd.fields.email.name,
      key: umd.fields.email.name,
      render: (text) => <>{text}</>,
    },
    {
      title: umd.fields.username.label,
      dataIndex: umd.fields.username.name,
      key: umd.fields.username.name,
      render: (text) => <>{text}</>,
    },
    {
      title: umd.fields.is_admin.label,
      dataIndex: umd.fields.is_admin.name,
      key: umd.fields.is_admin.name,
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: umd.fields.tenant.label,
      dataIndex: umd.fields.tenant.name,
      key: umd.fields.tenant.name,
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: umd.fields.subscription.label,
      dataIndex: umd.fields.subscription.name,
      key: umd.fields.subscription.name,
      width: '300px',
      render: (text: UserAdminResponse['subscription'], record) => (
        <>
          <Select
            style={{
              width: '100%',
            }}
            value={text?.plan?.name}
            options={planOptions}
            onChange={(plan_id: string) => {
              subscriptionSet({
                userSubscriptionUpdate: {
                  plan_id,
                  user_id: record.id,
                },
              }).then(() => {
                dispatch(userApi.util.invalidateTags(['/user']))
              })
            }}
          />
        </>
      ),
    },
    {
      title: umd.fields.roles.label,
      dataIndex: umd.fields.roles.name,
      key: umd.fields.roles.name,
      render: (text, record) => (
        <>
          {record.roles.map(({ name }) => (
            <Tag>{name}</Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      key: 'Delete',
      render: (text, record) => (
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this task?"
          onConfirm={async () => {
            await deleteUser({ id: record.id })
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
    {
      title: 'Activate',
      dataIndex: 'Activate',
      key: 'Activate',
      render: (text, record) => (
        <Switch
          defaultChecked
          value={record.is_activated}
          onChange={async () => {
            console.log(record.id)
            await activate({
              email: record.email,
              activatedRequest: { is_activated: !record.is_activated },
            })
          }}
        />
      ),
    },
  ]

  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(1)

  return (
    <Page
      title={t(AdminUsersRoute.title)}
      layoutStyle={{ overflow: 'auto', height: '100%' }}
    >
      <Debug debug objects={{ planOptions, plans }} />
      <Debug debug objects={{ data }} />
      <MyTable
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize,
          current: page,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page)
          },
        }}
      />
    </Page>
  )
}
