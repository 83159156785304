import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

type Props = {}
const { Text, Title } = Typography
export default function ChooseSubscriptionTitle({}: Props) {
  const { t } = useTranslation()
  return (
    <div style={{ textAlign: 'center', marginBottom: '40px' }}>
      <Title level={2}>{t('edu.subscription.modal.title')}</Title>
      <Text>{t('edu.subscription.modal.offerText')}</Text>
    </div>
  )
}
