import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import enUS from 'antd/locale/en_US'
import kkKZ from 'antd/locale/kk_KZ'
import ruRU from 'antd/locale/ru_RU'

import type { TSupportedLangs } from '../../i18n'
// ----------------------------------------------------------------------

export type tRole = 'client' | 'admin' | 'user'
const mapLocale = (code: TSupportedLangs) => {
  switch (code) {
    case 'en-US':
      return enUS
    case 'kk-KZ':
      return kkKZ
    case 'ru-RU':
      return ruRU
  }
}
export interface MainState {
  isDarkMode: boolean
  isLoginOpen: boolean
  isPromoOpen: boolean
  locale: typeof enUS | typeof kkKZ | typeof ruRU
  env: tRole
}

const DARK_MODE = 'dark-mode'
const ENV = 'env'

const possibleEnvs: tRole[] = ['admin', 'user', 'client']

const getEnv = (): tRole => {
  const currentEnv = localStorage.getItem(ENV)
  return possibleEnvs.includes(currentEnv as tRole)
    ? (currentEnv as tRole)
    : 'client'
}

const initialState: MainState = {
  isDarkMode: localStorage.getItem(DARK_MODE) === 'true' || false,
  env: getEnv(),
  isPromoOpen: false,
  locale: mapLocale(
    (localStorage.getItem('LanguageState') as TSupportedLangs) || 'kk-KZ',
  ),
  isLoginOpen: false,
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsDarkMode: (state, action: PayloadAction<{ isDarkMode: boolean }>) => {
      const darkMode = action.payload.isDarkMode
      localStorage.setItem(DARK_MODE, darkMode ? 'true' : 'false')
      state.isDarkMode = darkMode
    },
    setEnv: (state, action: PayloadAction<{ env: tRole }>) => {
      const env = action.payload.env
      localStorage.setItem(ENV, env)
      state.env = env
    },
    setIsLoginOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginOpen = action.payload
    },
    setLocale: (state, action: PayloadAction<TSupportedLangs>) => {
      state.locale = mapLocale(action.payload)
    },
    setIsPromoOpen: (state, action: PayloadAction<boolean>) => {
      state.isPromoOpen = action.payload
    },
  },
})

export const {
  setIsDarkMode,
  setEnv,
  setIsLoginOpen,
  setLocale,
  setIsPromoOpen,
} = mainSlice.actions
export default mainSlice.reducer
