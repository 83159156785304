import type { DatePickerProps } from 'antd'
import { DatePicker, Form } from 'antd'
import type { FormItemProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

import type { FormElementProps } from './helpers'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  datePickerProps?: DatePickerProps
} & FormElementProps

export default function FormDatePicker({
  formField,
  itemProps,
  datePickerProps,
  displayElement,
  editing = true,
}: Props) {
  const id = useId()
  const { t } = useTranslation()
  return (
    <Form.Item
      wrapperCol={{
        style: {
          justifyContent: 'center',
        },
      }}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      required={false}
      name={formField.name}
      rules={formField.rules}
      {...itemProps}
    >
      {editing ? (
        <DatePicker
          style={{
            justifyContent: 'center',
            width: '100%',
          }}
          showNow={false}
          placeholder={t(formField.placeholder)}
          {...datePickerProps}
        />
      ) : (
        displayElement
      )}
    </Form.Item>
  )
}
