import { Row, Steps } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import {
  findPlan,
  transformPlan,
} from '../../components/SubscriptionForm/components/PlanCard'
import { usePlanListQuery } from '../../redux/api/edu/plan'
import { PaymentRoute } from '../../routes'
import { params, paths } from '../../routes/paths'
import { updateSearchParams } from '../../utils/router'

import PaymentStep from './components/PaymentStep'
import SubscriptionChoiceStep from './components/SubscriptionChoiceStep'

interface Props {}
export default function PaymentPage({}: Props) {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth)
  const [current, setCurrent] = useState(0)

  const onChange = (value: number) => {
    console.log('onChange:', value)
    setCurrent(value)
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const planId = searchParams.get('plan_id')

  const navigate = useNavigate()

  useEffect(() => {
    if (!planId) {
      onChange(0)
    }
  }, [planId, current])

  const { data } = usePlanListQuery({})

  const proPlan = findPlan(data, 'Pro')

  const plans = [proPlan]
    .filter((plan) => plan !== undefined)
    .map((plan) => transformPlan(plan, t))
  const plan = plans.find(({ id }) => id === planId)

  return (
    <Page title={t(PaymentRoute.title)} style={{ minHeight: '1080px' }}>
      {/* <Title level={3}>{t(PaymentRoute.title)}</Title> */}
      <Debug objects={{ current }} debug />
      <Debug objects={{ user }} debug />
      <Row gutter={50}></Row>
      <Steps
        current={current}
        onChange={onChange}
        items={[
          {
            title: t('edu.subscription.form.steps.subscriptionChoice'),
            // description,
          },
          // {
          //   title: 'Выбор способа оплаты',
          //   // description,
          // },
          {
            title: t('edu.subscription.form.steps.payment'),
            // description,
          },
        ]}
      />
      {current === 0 && (
        <SubscriptionChoiceStep
          planId={planId}
          plans={plans}
          onPlanSelect={(planId) => {
            updateSearchParams(
              { [params.plan_id.slice(1)]: planId },
              searchParams,
              setSearchParams,
            )
            onChange(1)
          }}
        />
      )}
      {/* {current === 1 && <PaymentTypeChooseStep onSuccess={() => onChange(2)} />} */}
      {current === 1 && plan && (
        <PaymentStep
          onSuccess={() => navigate(paths.eduPlayground)}
          plan={plan}
        />
      )}
    </Page>
  )
}
