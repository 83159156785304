import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { TPlan } from '../../../components/SubscriptionForm/components/PlanCard'

import KaspiTransferForm from './KaspiTransferForm'
import PaymentDescription from './PaymentDescription'
import StripeForm from './StripeForm'

type Props = {
  onSuccess: () => void
  plan: TPlan
}

export default function PaymentStep({ plan }: Props) {
  const [selectedPayment, setSelectedPayment] = useState<
    'kaspi_transfer' | 'stripe'
  >('kaspi_transfer')

  const { t } = useTranslation()
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div>
        <Layout style={{ width: 'fit-content' }}>
          <Sider width="30%" style={{ background: 'white' }}>
            <PaymentDescription plan={plan} />
          </Sider>
          <Content
            style={{ background: 'white', maxWidth: '900px', width: '100%' }}
          >
            <div>
              {selectedPayment === 'kaspi_transfer' && (
                <KaspiTransferForm plan_id={plan.id} />
              )}
              {selectedPayment === 'stripe' && <StripeForm />}
            </div>
          </Content>
        </Layout>
      </div>
    </div>
  )
}
