import { usePlanListQuery } from '../../redux/api/edu/plan'

import BlogSection from './components/components/BlogSection'
import CollaborationSection from './components/components/CollaborationSection'
import ContactSection from './components/components/ContactSection'
import FeaturesHighlight from './components/components/FeaturesHighlight'
import Footer from './components/components/Footer'
import HeroSection from './components/components/HeroSection'
import PromotionalBanner from './components/components/PromotionalBanner'
import SubscriptionPlans from './components/components/SubscriptionPlans'

function App() {
  const { data: plans } = usePlanListQuery({})

  const basicPlan = plans?.find(({ name }) => name === 'basic')
  const proPlan = plans?.find(({ name }) => name === 'pro')
  return (
    <div>
      <HeroSection />
      <FeaturesHighlight />
      {basicPlan && proPlan && (
        <SubscriptionPlans basicPlan={basicPlan} proPlan={proPlan} />
      )}
      <BlogSection />
      <PromotionalBanner />
      <CollaborationSection />
      <ContactSection />
      <Footer />
    </div>
  )
}

export default App
