export default {
  fields: {
    discipline_id: {
      label: 'Тапсырыс',
      placeholder: 'Тапсырысты енгізіңіз',
    },
    title: {
      label: 'Атауы',
      placeholder: 'Атауын енгізіңіз',
    },
    instruction: {
      label: 'Нұсқаулық',
      placeholder: 'Нұсқаулықтарды енгізіңіз',
      default: {
        'Questions to be researched': `<TEMPLATE>
Questions to be researched kk
</TEMPLATE>
`,
        'Test generation': `<TEMPLATE>
Test generation kk
</TEMPLATE>`,
        'Essay writing': `<TEMPLATE>
Essay writing kk
</TEMPLATE>`,
        'Quiz creation': `<TEMPLATE>
Quiz creation kk
</TEMPLATE>`,
        'Material preparation': `<TEMPLATE>
Material preparation kk
</TEMPLATE>`,
        'Lesson planning': `<TEMPLATE>
Lesson planning kk
</TEMPLATE>`,
        'Pedagogical advice': `<TEMPLATE>
Pedagogical advice kk
</TEMPLATE>`,
        'Analysis and estimation': `<TEMPLATE>
Analysis and estimation kk
</TEMPLATE>`,
      },
    },
    input_text: {
      label: 'AI үшін мәтін',
      placeholder: 'AI ескеруі тиіс мәтінді енгізіңіз...',
    },
    result_text: {
      label: 'AI нәтижесі',
      placeholder: 'AI нәтижесін енгізіңіз',
    },
    task_type: {
      label: 'Тапсырма түрі',
      placeholder: 'Тапсырма түрін таңдаңыз',
      tag: '{{task_type}}',
      options: {
        'Quiz creation': 'Сауалнама құру',
        'Test generation': 'Тест жасау',
        'Questions to be researched': 'Зерттелетін сұрақтар',
        'Essay writing': 'Эссе жазу',
        'Lesson planning': 'Сабақты жоспарлау',
        'Material preparation': 'Материал дайындау',
        'Pedagogical advice': 'Педагогикалық кеңес',
        'Analysis and estimation': 'Талдау және бағалау',
      },
    },
    status: {
      label: 'Статус',
      tag: '{{status}}',
      placeholder: 'Статусты таңдаңыз',
      options: {
        'In Progress': 'Үдерісте',
        Completed: 'Аяқталды',
        Deleted: 'Жойылды',
        Failed: 'Сәтсіз аяқталды',
        Created: 'Жасалды',
      },
    },
    response_language: {
      label: 'Тіл',
      placeholder: 'Тілді таңдаңыз',
      options: {
        English: 'Ағылшын',
        Russian: 'Орыс',
        Kazakh: 'Қазақ',
      },
    },
    level: {
      label: 'Деңгей',
      placeholder: 'Деңгейді таңдаңыз',
      options: {
        Advanced: 'Жетілдірілген',
        Beginner: 'Бастауыш',
        Expert: 'Сарапшы',
        Intermediate: 'Орташа',
      },
    },
    category_id: {
      label: 'Санат',
    },
    sub_category_id: {
      label: 'Ішкі санат',
    },
    teachers_discipline_id: {
      label: 'Пән',
    },
  },
  form: {
    title: {
      create: 'Тапсырыс жасау',
      update: 'Тапсырысты жаңарту',
    },
    toast: {
      updated: 'Тапсырыс сәтті жаңартылды',
      created: 'Тапсырыс сәтті жасалды',
      deleted: 'Тапсырыс сәтті жойылды',
    },
    actions: {
      create: 'Жаңа тапсырыс',
      save: 'Сақтау',
      edit: 'Өзгерту',
      generateDocx: {
        title: 'DOCX',
        finishGeneration: 'DOCX құру. Жауапты құруды аяқтаңыз.',
        limitExceeded:
          'DOCX құру. Сіз лимитке жеттіңіз ({{ limit }} пайдаланылды).',
      },
      approve: {
        title: 'Жіберу',
        hint: 'Тапсырманы өңдеу үшін экранның төменгі оң жақ бұрышындағы жасыл түймені басыңыз',
        alreadyRun: 'Тапсырыс бұрыннан жіберілген',
        limitExceeded: 'Сіз лимитке жеттіңіз ({{ limit }} пайдаланылды).',
      },
      expand: {
        title: 'Жақсарту',
        finishGeneration: 'Жауапты жақсарту. Жауапты құруды аяқтаңыз.',
        limitExceeded:
          'Жауапты жақсарту. Сіз лимитке жеттіңіз ({{ limit }} пайдаланылды).',
      },
      regenerate: {
        title: 'Қайта жасау',
        finishGeneration: 'Қайта жасау. Жауапты құруды аяқтаңыз.',
        limitExceeded:
          'Қайта жасау. Сіз лимитке жеттіңіз ({{ limit }} пайдаланылды).',
      },
      delete: {
        title: 'Жою',
        popConfirm: {
          title: 'Тапсырысты жою',
          description: 'Сіз осы тапсырысты жоюға сенімдісіз бе?',
        },
      },
      like: 'Ұнату',
    },
  },
}
