import { Card, Typography } from 'antd'
import type { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import Debug from '../../../components/helpers/Debug'
import type { OrderRunResponse } from '../../../redux/api/edu/order_run'

import type { TResultType } from './PlaygroundBody'

const headingStyle: CSSProperties = {
  fontSize: 20,
  color: 'var(--violet)', // Main violet color
}

const paragraphStyle: CSSProperties = {
  fontSize: 16,
  color: 'var(--violet)', // Main violet color
  lineHeight: 1.5,
}

const tableStyle: CSSProperties = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '1rem',
  backgroundColor: '#fff',
  border: '1px solid var(--violet)',
  borderRadius: '8px', // Slight rounding
  overflow: 'hidden', // Ensure rounded corners apply
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
}

const thStyle: CSSProperties = {
  backgroundColor: 'var(--violet)',
  color: '#fff',
  fontWeight: 'bold',
  border: '1px solid var(--violet)',
  padding: '10px',
  textAlign: 'left',
  fontSize: '16px',
}

const tdStyle: CSSProperties = {
  border: '1px solid var(--violet)',
  padding: '10px',
  textAlign: 'left',
  fontSize: '14px',
}

const trHoverStyle: CSSProperties = {
  backgroundColor: 'var(--orange)',
  color: '#fff',
  cursor: 'pointer',
}

const tableCss = `
  table tbody tr:hover {
    background-color: var(--orange);
    color: #fff;
    cursor: pointer;
  }
`

export default function RunBody({
  resultType,
  orderRun,
}: {
  orderRun: OrderRunResponse
  resultType: TResultType
}) {
  const { t } = useTranslation()
  const markdown =
    resultType === 'expanded_result' && orderRun.expanded_result
      ? orderRun.expanded_result.response
      : orderRun.completion_result?.response

  return (
    <>
      <Card
        style={{
          width: '100%',
          borderWidth: 3,
          backgroundColor: '#fff',
          overflowX: 'auto',
        }}
      >
        <Debug debug objects={{ markdown, orderRun }} />
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            h2: ({ node, ...props }) => (
              <Typography.Title level={2} style={headingStyle} {...props} />
            ),
            p: ({ node, ...props }) => (
              <Typography.Paragraph style={paragraphStyle} {...props} />
            ),
            table: ({ node, ...props }) => (
              <table style={tableStyle} {...props} />
            ),
            thead: ({ node, ...props }) => <thead {...props} />,
            tbody: ({ node, ...props }) => <tbody {...props} />,
            th: ({ node, ...props }) => <th style={thStyle} {...props} />,
            td: ({ node, ...props }) => <td style={tdStyle} {...props} />,
          }}
        >
          {markdown}
        </ReactMarkdown>
      </Card>
    </>
  )
}
