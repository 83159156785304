import { Typography } from 'antd'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { usePlanListQuery } from '../../../redux/api/edu/plan'

import ChooseSubscriptionTitle from './ChooseSubscriptionTitle'
import type { TPlan } from './PlanCard'
import PlanCard, { findPlan, transformPlan } from './PlanCard'

const { Title, Text } = Typography
type Props = {
  renderButton?: (plan: TPlan) => ReactElement
}

export default function PlanComparison({}: Props) {
  const { t } = useTranslation()

  const { data } = usePlanListQuery({})

  const basicPlan = findPlan(data, 'Basic')
  const proPlan = findPlan(data, 'Pro')

  const plans = [proPlan, basicPlan].filter((plan) => plan !== undefined)
  const pricingData: TPlan[] = plans.map((val) => transformPlan(val, t))

  return (
    <div style={{ padding: '20px' }}>
      <ChooseSubscriptionTitle />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          marginBottom: '40px',
        }}
      >
        {pricingData.map((plan) => (
          <PlanCard plan={plan} renderButton={true} />
        ))}
      </div>
    </div>
  )
}
