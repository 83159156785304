import { eduApi as api } from './index'
export const addTagTypes = ['subscription'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      subscriptionSet: build.mutation<
        SubscriptionSetApiResponse,
        SubscriptionSetApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/subscription/set-subscription`,
          method: 'POST',
          body: queryArg.userSubscriptionUpdate,
        }),
        invalidatesTags: ['subscription'],
      }),
      subscriptionRequest: build.mutation<
        SubscriptionRequestApiResponse,
        SubscriptionRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/subscription/request`,
          method: 'POST',
          body: queryArg.subscriptionRequestBody,
        }),
        invalidatesTags: ['subscription'],
      }),
      subscriptionRetrieve: build.query<
        SubscriptionRetrieveApiResponse,
        SubscriptionRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/edu/subscription/${queryArg.id}` }),
        providesTags: ['subscription'],
      }),
      subscriptionList: build.query<
        SubscriptionListApiResponse,
        SubscriptionListApiArg
      >({
        query: () => ({ url: `/edu/subscription/` }),
        providesTags: ['subscription'],
      }),
      updateSubscriptionStatus: build.mutation<
        UpdateSubscriptionStatusApiResponse,
        UpdateSubscriptionStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/subscription/subscription/${queryArg.subscriptionId}/status`,
          method: 'POST',
          body: queryArg.updateSubscriptionRequest,
        }),
        invalidatesTags: ['subscription'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as subscriptionApi }
export type SubscriptionSetApiResponse =
  /** status 200 Successful Response */ SubscriptionResponse
export type SubscriptionSetApiArg = {
  userSubscriptionUpdate: UserSubscriptionUpdate
}
export type SubscriptionRequestApiResponse =
  /** status 201 Successful Response */ SubscriptionResponse
export type SubscriptionRequestApiArg = {
  subscriptionRequestBody: SubscriptionRequestBody
}
export type SubscriptionRetrieveApiResponse =
  /** status 200 Successful Response */ SubscriptionResponse
export type SubscriptionRetrieveApiArg = {
  id: number
}
export type SubscriptionListApiResponse =
  /** status 200 Successful Response */ SubscriptionResponse[]
export type SubscriptionListApiArg = void
export type UpdateSubscriptionStatusApiResponse =
  /** status 200 Successful Response */ SubscriptionResponse
export type UpdateSubscriptionStatusApiArg = {
  subscriptionId: number
  updateSubscriptionRequest: UpdateSubscriptionRequest
}
export type FeatureName =
  | 'order_submission'
  | 'expanding_result'
  | 'document_generation'
export type PlanFeatureResponse = {
  id: string
  feature_name: FeatureName
  feature_limit?: number | null
  is_unlimited: boolean
  is_enabled: boolean
  description?: string | null
}
export type PlanResponse = {
  id: string
  name: string
  description: string | null
  price: number | null
  billing_cycle_days: number | null
  is_active: boolean
  is_trial_available: boolean
  trial_days: number | null
  created_by: string | null
  created_at: string
  features?: PlanFeatureResponse[]
}
export type SubscriptionStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'CANCELED'
  | 'EXPIRED'
  | 'PENDING'
export type SubscriptionResponse = {
  id: number
  user_id: string
  plan: PlanResponse
  phone_number: string | null
  status: SubscriptionStatus
  valid_until: string
  start_date: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type UserSubscriptionUpdate = {
  user_id: string
  plan_id: string
  valid_until?: string | null
}
export type SubscriptionRequestBody = {
  plan_id: string
  phone_number: string
}
export type SubscriptionAction = 'activate' | 'cancel' | 'expire'
export type UpdateSubscriptionRequest = {
  action: SubscriptionAction
}
export const {
  useSubscriptionSetMutation,
  useSubscriptionRequestMutation,
  useSubscriptionRetrieveQuery,
  useLazySubscriptionRetrieveQuery,
  useSubscriptionListQuery,
  useLazySubscriptionListQuery,
  useUpdateSubscriptionStatusMutation,
} = injectedRtkApi
