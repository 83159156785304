import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  SubcategoryBody,
  SubcategoryResponse,
} from '../../api/edu/sub_category'
export const getSubCategoryModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<SubcategoryBody & SubcategoryResponse>(
    {
      id: {},
      title: {},
      teachers_discipline_id: {},
      category_id: {},
    },
    t,
    'edu.subCategory',
  )
  return nd
}
