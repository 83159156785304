import { QuestionCircleOutlined } from '@ant-design/icons'
import type { InputProps, SelectProps } from 'antd'
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import type { FormItemProps } from 'antd/lib'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import FormDatePicker from '../../../components/Form/DatePicker'
import type { IFormBody } from '../../../components/Form/helpers'
import FormInput from '../../../components/Form/Input'
import RegionSelect from '../../../components/Form/RegionSelect'
import SexSelect, { mapSex } from '../../../components/Form/SexSelect'
import Debug from '../../../components/helpers/Debug'
import type { UserResponse } from '../../../redux/api/auth/auth'
import type { UpdateUserRequest } from '../../../redux/api/auth/user'
import { useUserUpdateMutation } from '../../../redux/api/auth/user'
import { useOrderGetDailyUsageStatisticsQuery } from '../../../redux/api/edu/order'
import { getUserModelDescriptor } from '../../../redux/slices/auth/userSlice'
import { ProfileRoute } from '../../../routes'
import { parseDate } from '../../../utils/parsers'
import { formatDate } from '../../../utils/time'

const { Title, Text } = Typography

type Props = {
  user: UserResponse
}

/**
 * A small helper component to keep label-value pairs consistent.
 */
function ProfileField({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) {
  return (
    <Row
      style={{
        marginBottom: 8,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Col style={{ fontWeight: 600 }}>{label}</Col>
      <Col style={{ overflowWrap: 'break-word' }}>{children}</Col>
    </Row>
  )
}

export default function ProfileCard({ user }: Props) {
  const { t } = useTranslation()
  const { data: usage } = useOrderGetDailyUsageStatisticsQuery()
  const userMD = getUserModelDescriptor(t)

  const [editing, setEditing] = useState<boolean>(false)
  type FormBody = IFormBody<Partial<UpdateUserRequest>, 'date_of_birth'>

  const [form] = Form.useForm<FormBody>()
  const initialValues: FormBody = {
    first_name: user.first_name || undefined,
    last_name: user.last_name || undefined,
    date_of_birth: parseDate(user.date_of_birth),
    region: user.region,
    sex: user.sex || undefined,
    school: user.school,
  }
  const values = Form.useWatch([], form)

  // Build an array of usage stats to display in the table
  const usageData = usage
    ? Object.entries(usage.usage).map(([feature, usageStats]) => ({
        key: feature,
        feature,
        used: usageStats.used,
        remaining: usageStats.remaining,
      }))
    : []

  // Define columns for the usage table
  const usageColumns = [
    {
      title: t('pages.profile.user.descriptions.usage.feature'), // or "Feature"
      dataIndex: 'feature',
      key: 'feature',
    },
    {
      title: t('pages.profile.user.descriptions.usage.used'), // or "Used"
      dataIndex: 'used',
      key: 'used',
    },
    {
      title: t('pages.profile.user.descriptions.usage.remaining'), // or "Remaining"
      dataIndex: 'remaining',
      key: 'remaining',
    },
  ]

  const [userUpdate, { isSuccess }] = useUserUpdateMutation()

  useEffect(() => {
    if (isSuccess) {
      userMD.toast.updated()
    }
  }, [isSuccess])

  const itemProps: FormItemProps<any> = { label: null }

  const inputProps: InputProps = {
    size: 'small',
    style: {
      minWidth: '250px',
    },
  }
  const selectProps: SelectProps = {
    size: 'small',
    style: {
      minWidth: '250px',
    },
  }
  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={({ ...props }) => {
        if (!isEqual(initialValues, values)) {
          userUpdate({
            updateUserRequest: {
              ...props,
              date_of_birth: formatDate(props.date_of_birth, 'YYYY-MM-DD'),
            },
          })
        }
        setEditing(false)
      }}
    >
      <Card style={{ width: '100%' }}>
        <Title
          level={3}
          style={{ display: 'flex', alignItems: 'center', gap: 8 }}
        >
          {t(ProfileRoute.title)}
        </Title>

        <Debug debug objects={{ editing, values }} />
        <Debug debug objects={{ initialValues }} />
        <Debug debug objects={{ usageData }} />

        <Row gutter={[36, 8]}>
          {/* Email */}
          <Col xs={24}>
            <ProfileField
              label={t('pages.profile.user.descriptions.promo.title')}
            >
              <Tag
                color="purple-inverse"
                style={{ padding: 5, margin: 5, fontSize: 14 }}
              >
                {uuidv4()}
              </Tag>
              <Tooltip
                title={t('pages.profile.user.descriptions.promo.tooltip')}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </ProfileField>
          </Col>

          {/* Email */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.email.label}>
              <Text>{user.email}</Text>
            </ProfileField>
          </Col>

          {/* Tenant */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.tenant.label}>
              <Text>{user.tenant?.name}</Text>
            </ProfileField>
          </Col>

          {/* First Name */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.first_name.label}>
              {editing ? (
                <FormInput
                  formField={userMD.fields.first_name}
                  inputProps={inputProps}
                  itemProps={itemProps}
                />
              ) : (
                <Text>{user.first_name}</Text>
              )}
            </ProfileField>
          </Col>

          {/* Last Name */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.last_name.label}>
              {editing ? (
                <FormInput
                  formField={userMD.fields.last_name}
                  inputProps={inputProps}
                  itemProps={itemProps}
                />
              ) : (
                <Text>{user.last_name}</Text>
              )}
            </ProfileField>
          </Col>

          {/* Region */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.region.label}>
              {editing ? (
                <RegionSelect
                  formfield={userMD.fields.region}
                  selectProps={selectProps}
                  itemProps={itemProps}
                />
              ) : (
                <Text>{user.region}</Text>
              )}
            </ProfileField>
          </Col>

          {/* School */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.school.label}>
              {editing ? (
                <FormInput
                  formField={userMD.fields.school}
                  inputProps={inputProps}
                  itemProps={itemProps}
                />
              ) : (
                <Text>{user.school}</Text>
              )}
            </ProfileField>
          </Col>

          {/* Sex */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.sex.label}>
              {editing ? (
                <SexSelect
                  formField={userMD.fields.sex}
                  selectProps={selectProps}
                  itemProps={itemProps}
                />
              ) : (
                <Text>{user.sex ? mapSex(user.sex, t) : null}</Text>
              )}
            </ProfileField>
          </Col>

          {/* Date of Birth */}
          <Col xs={24}>
            <ProfileField label={userMD.fields.date_of_birth.label}>
              {editing ? (
                <FormDatePicker
                  formField={userMD.fields.date_of_birth}
                  datePickerProps={{
                    size: 'small',
                    style: { minWidth: '250px' },
                  }}
                  itemProps={itemProps}
                />
              ) : (
                <Text>{user.date_of_birth}</Text>
              )}
            </ProfileField>
          </Col>

          {/* Usage */}
          {/* Usage Table */}
          <Col span={24}>
            <ProfileField
              label={t('pages.profile.user.descriptions.usage.title')}
            >
              {usageData.length > 0 ? (
                <Table
                  columns={usageColumns}
                  dataSource={usageData}
                  pagination={false}
                  size="small"
                  bordered
                  style={{ maxWidth: '600px' }}
                />
              ) : (
                <Text>{t('no usage info')}</Text>
              )}
            </ProfileField>
          </Col>
        </Row>

        <Button
          type="primary"
          onClick={() => {
            if (editing) {
              form.submit()
            } else setEditing(true)
          }}
        >
          {editing
            ? userMD.form.action.describe('save')
            : userMD.form.action.update()}
        </Button>
      </Card>
    </Form>
  )
}
